.checkout {
	&__collapse {
		display: flex;
    	flex-direction: row;
		background-color: c(background-grey);
		border-color: c(background-grey);
		border: 1px solid transparent;
	    color: c(grey);
	    padding: 15px;
	    width: 100%;
	    position: relative;
	    padding-left: 60px;
	    line-height: 24px;
	   &:first-child {
		    margin-bottom: 20px;
	   }
	    font: {
			family: f(roboto);
			size: 13px;
		}
		&--acceder{
			color: c(blue-green);
		}
		&:before{
			content: "\f129";
			position: absolute;
			top: 50%;
			left: 25px;
			margin-top: -11px;
			line-height: 1;
			font-size: 24px;
			font-family: FontAwesome;
		}
	}
	&__inputs {
		@include under(xs) {
			font-size: 13px;
		}
		background-color: c(white);
		border: 1px solid c(lighten-grey);
		border-radius: initial;
		padding: 10px;
		height: 39px;
		font-weight: lighter;
		font-size: 14px;
		margin: 5px 0px 15px 0px;
		&--single {
			margin: 25px 0px 40px 0px;
		}
	}
	&__select {
		border-radius: 7px;
		margin: 5px 0px 15px 0px;
	}
	&__datos {
		@include under(sm) {
			width: 100%;
			margin-bottom: 0px;
		}
		font-family: f(roboto);
		width: 60%;
		padding:20px;
		margin-bottom: 60px;
		&--ttl {
			width: 100%;
		    display: flex;
		    flex-direction: row;
		    align-items: center;
		}
	}
	&__two-inputs {
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include under(xs) {
			flex-wrap: wrap;
		}
		&--container {
		    width: calc(100%/2);
			@include under(xs) {
				width: 100%;
			}
			&:first-of-type {
				@include under(xs) {
					margin-right: 15px;
				}
				margin-right: 10px;
			}
		}
	}
	&__discount {
		flex-direction: column;
		flex-wrap: wrap;
		align-items: baseline;
		&-submit {
			width: auto;
			margin-top: 20px;
			background-color: c(blue-green);
			font-weight: 500;
			color: c(white);
			letter-spacing: 1px;
			border: none;
			cursor: pointer;
			font-size: 13px;
			height: 40px;
			line-height: 40px;
			padding: 0px 28px;
			text-align: center;
			text-transform: uppercase;
		}
	}
	&__labels {
		@include under(xs) {
			font-size: 13px;
		}
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 5px;
		color: c(grey);
	}
	&__pedido {
		family: f(roboto);
		width: 40%;
	}
	 &__required {
		color: red;
		font-size: 14px;
		line-height: 22px;
	}
	&__new-card-container {
		margin: 20px 0px;
	}
	&__card-label {
		display: flex;
		align-items: center;
		@include under(xs) {
			margin-bottom: 10px;
		}
	}
	&__text {
		color: c(grey);
		line-height: 23.4px;
		margin-bottom: 20px;
		font-size: 13px;
		font-family: f(roboto);
		&--no-mb {
			margin-bottom: 0;
		}
		&--no-hover {
			&:hover {
				color: c(grey) !important;
			}
			&:last-of-type {
				&:hover {
					color: c(grey) !important;
				}
			}
		}
		&--other-color {
			&:last-of-type {
				color: c(grey) !important;
			}
		}
		&:last-of-type {
			color: c(black);
			&:hover {
				color: c(blue-green);
			}
		}
	}
	&__address {
		color: c(grey);
		line-height: 23.4px;
		font-size: 14px;
		font-family: f(roboto);
		&-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: baseline;
			align-items: center;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 20px;
			}
			&--baseline {
				align-items: baseline;
			}
		}
		&--width {
			width: calc(100% - 26px);
		}
	}
	&__checkbox {
		display: flex;
		flex-direction: row;
		margin: 15px 0px 13px 0px;
		input {
			margin-right: 10px;
		}
		p {
			font-size: 13px;
		}
	}
	&__textarea {
		height: 120px;
		line-height: 19.5px;
		padding: 9px 6px;
		margin: 5px 0px;
	}

	&__pedido {
		@include under(sm) {
			width: 100%;
			padding: 10px !important;
			margin-top: 0px;
		}
		font-family: f(roboto);
		padding: 20px;
		border: 5px solid c(background-grey);
    	margin: 48px 0px 60px 0px;
		&--ttl {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		&--card {
			display: flex;
			align-items: center;
			margin: 10px 0px;
			margin-bottom: 25px;
			flex-wrap: wrap;
			span {
				margin-bottom: 0px !important;
				margin-left: 10px;
			}
			img {
				width: 140px;
				margin-left: 10px;
			}
		}
		&--trans {
			color: c(grey);
			line-height: 23.4px;
			font-size: 13px;
			font-family: f(roboto);
			padding: 15px 0px 24px;
			border-bottom: solid 1px  c(ultralight-grey);
			border-top: solid 1px  c(ultralight-grey);
			input {
				border-radius: 3px;
			}
		}
		&--paypalContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
			flex-wrap: wrap;
		}
		&--imgCOntainer {
			max-width: 140px;
		}
		&--queEs {
			font-size: 13px;
			color: c(black-dark);
			text-decoration: none;
			&:hover {
				text-decoration: underline;
				color: c(blue-green);

			}
		}
		&--paypal {
			border-top:none;
		}
		&--terms {
			margin-top: 28px;
			font-size: 14px;
			span:first-of-type {
				color: c(grey);
			}
		}
		&--btn {
			background-color: c(blue-green);
			color: c(white);
			letter-spacing: 1px;
			border: none;
			cursor: pointer;
			font-size: 13px;
			height: 40px;
			line-height: 40px;
			padding: 0px 28px;
			text-align: center;
			margin-top: 27px;
			font-family: f(roboto);
			font-weight: 500;
		}
	}
	&__table {
		display: flex;
		flex-direction: column;
		font: {
		   family: f(roboto);
		   size: 13px;
	   }
		&--row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: solid 1px c(lighten-grey);
			padding: 23px 10px 30px 10px;
			&:last-of-type {
				border-bottom: none;
			}
			span:last-child {
				font-weight: 600;
			}
		}
		&--total {
			font-weight: 600;
		}
		&--pedido {
			width: calc(80% - 5px);
		}
		&--price {
			width: calc(20% - 5px);
			text-align: right;
		}
	}
	&__selects--date {
		width: auto;
		display: block;
		margin-bottom: 24px;
	}
}
.ttl {
	&--txt {
		font: {
			size: 15px;
			family: f(roboto);
			weight: 600;
		}
		letter-spacing: 1px;
		color: #000;
		line-height: 20px;
		margin-right: 10px;
		width: 22%;
		max-width: 150px;
		text-transform: uppercase;
		&-cupon {
			width: 35%;
			max-width: 200px;
		}
	}
	&--line {
		height: 1px;
		background-color: #E4E4E4;
		width: 78%;
		margin: 32px 0px;
		&-cupon {
			width: 65%;
		}
	}
}
.labels__pedidos {
    font-size: 14.3px;
}
