/*%title {
    font-family: f(sans);
    size: 12px;
    font-weight: normal;
    color: c();
    text-transform: uppercase;
}

%title-description {
    @extend %title;
    font-family: f(inputs);
    font-style: italic;
}

%title-img {
  @extend %title;
  color: c(beige);
}

%title-bill {
    @extend %title;
    size: 13px;
    color: c(brown);
}*/

//---header top label, iniciar sesion ---//
%label-menu, .label-menu{
    font: {
        family: f(roboto);
        size: 12px;
        text-transform: uppercase;
    }
}

//---header top---//
%menu-items, .menu-items{
    font: {
        family: f(roboto);
        size: 13px;
        color:#000;
        text-transform: uppercase;
    }
    @include under(xs) {    size: 14px; }
    // color: c(black);
    // text-transform: uppercase;
    // line-height: 15px;
    // @include under(xs) {    font-size: 14px; }
}
//---Menu, dropdown menu---//

%nav-sublist-dropdown, .nav-sublist-dropdown{
    font: {
        family: f(roboto);
        size: 12px;
        text-transform: uppercase;
    }

}
//---Home, titulo slider---//
%ttl-slider{
    font: {
        family: f(benchNine);
        size: 88px;
        line-height: 68px;
    }
}

//---Home, subtitulo slider---//
%sbtt-slider, .sbtt-slider{
    font: {
        family: f(robotoSlab);
        size: 16px;
    }
}

//---Home, Titulo cuadricula---//
%ttl-gridImg, .ttl-gridImg{
    font: {
        family: f(montserrat);
        size: 28px;
    }
    text-transform: uppercase;
}
//---Home, subtitulo cuadricula---//
%sbttl-gridImg, .sbttl-gridImg{
    font: {
        family: f(openSans);
        size: 14px;
    }
    text-transform: uppercase;
}

%sbttl-gridImgBuy, .sbttl-gridImgBuy{
    font: {
        family: f(montserrat);
        size: 14px;
        weight: 800;
    }
    text-transform: uppercase;
}

//---Home, titulo banner---//
%ttl-banner, .ttl-banner{
    font: {
        family: f(roboto);
        size: 44px;
        weight: 900;
    }
    text-transform: uppercase;
    color:#ff0000;
    line-height: 25px;
}
//---Home, subtitulo banner---//
%sbttl-banner, .sbttl-banner{
    font: {
        family: f(roboto);
        size: 13px;
        weight: 700;
    }
    text-transform: uppercase;
}
//---Home, subtitulo banner---//
%ttl-newsletter, .ttl-newsletter{
    font: {
        family: f(roboto);
        size: 24px;
        weight: 500;
    }
    text-transform: uppercase;
}
//---Home, titulo banners---//
%ttl-banner-inner, .ttl-banner-inner{
    font: {
        family: f(roboto);
        size: 18px;
        text-transform: uppercase;
        weight: 600;
    }
}

//---Home, footer titulo debajo de logo---//
%ttl-footer-contact, .ttl-footer-contact{
    font: {
        family: f(roboto);
        size: 12px;
        weight: 600;
    }
}
%ttl-footer-mail, .ttl-footer-mail{
    font: {
        family: f(roboto);
        size: 12px;
    }
}

//---Home, footer titulo de secciones---//
%ttl-footer-sections, .ttl-footer-sections{
    font: {
        family: f(roboto);
        size: 16px;
        weight: 600;
    }
}


//---Home, footer titulo de items (paginas: entrega, privacidad, etc)---//
%ttl-footer-items, .ttl-footer-items{
    font: {
        family: f(roboto);
        size: 13px;
        weight: 300;
    }
}

%ttl-footer-post, .ttl-footer-post{
    font: {
        family: f(roboto);
        size: 12px;
        weight: bold;
    }
    text-transform: uppercase;
    line-height: 24px;
}


//---index, Titulo de producto---//
%ttl-product, .ttl-product{
    font: {
        family: f(roboto);
        size: 14px;
        text-transform: uppercase;
        line-height: 20px;
    }
}
//---index, precio de producto---//
%sbttl-product-price, .sbttl-product-price{
    font: {
        family: f(roboto);
        size: 18px;
        text-transform: uppercase;
        weight: bold;
    }
}

//---show titulo de producto---//
%ttl-show-product, .ttl-show-product{
    font: {
        family: f(roboto);
        size: 30px;
        text-transform: uppercase;
        weight: 600;
    }
    text-transform: uppercase;
    line-height: 39px;
}
//---show titulo de producto---//
%sbttl-show-product, .sbttl-show-product{
    font: {
        family: f(roboto);
        size: 13px;
        line-height: 23px;
    }
}

//---show precio de producto---//
%sbttl-show-product-price, .sbttl-show-product-price{
    font: {
        family: f(roboto);
        size: 30px;
        weight: 600;
        line-height: 30px;
    }
}

//---show titulo en contenedores---//
%ttl-show-product-content, .ttl-show-product-content{
    font: {
        family: f(roboto);
        size: 13px;
        line-height: 19px;
    }
}

//---show titulos de productos en contenedores---//
%show-content-ttl-product, .show-content-ttl-product{
    font: {
        family: f(robotoSla);
        size: 13px;
        line-height: 19px;
    }
}

//---show Titulo mobile preview---//
%ttl-preview, .ttl-preview{
    font: {
        family: f(roboto);
        size: 18px;
        line-height: 23px;
        weight: 600;
    }
}

//---shop Titulo ---//
%ttl-shop-carrito, .ttl--shop-carrito{
    font: {
        family: f(roboto);
        size: 15px;
        weight: 500;
    }
    text-transform: uppercase;
    letter-spacing: 1px;
}
%ttl--carrito-product, .ttl--carrito-product{
    font: {
        family: f(roboto);
        size: 13px;
        weight: 100;
    }
    letter-spacing: 1px;
}



//---Titulo producto relacionado---//
%ttl-products-related, .ttl-products-related{
    font: {
        family: f(roboto);
        size: 22px;
        weight: normal;
    }
}

//---Titulo producto ---//
%sbttl-products-related, .sbttl-products-related{
    font: {
        family: f(roboto);
        size: 14px;
        weight: normal;

    }
    line-height: 20px;
}
//---precio de producto ---//
%price-products-related, .price-products-related{
    font: {
        family: f(roboto);
        size: 14px;
        weight: 500;
    }
    line-height: 20px;
}

//---FAQ subtitulos ---//
%sbttl-faq, .sbttl-faq{
    font: {
        family: f(roboto);
        size: 13px;
        weight: bold;
    }
    line-height: 23px;
}


//---blog titulos ---//
%ttl-blog, .ttl-blog{
    font: {
        family: f(roboto);
        size: 20px;
        weight:600;
    }
}

//---blog titulos comments ---//
%ttl-blog-commets, .ttl-blog-commets{
    font: {
        family: f(roboto);
        size: 18px;
        weight:500;
    }
    line-height: 20px;
}

%sbttl-blog-commets, .sbttl-blog-commets{
    font: {
        family: f(roboto);
        size: 14px;
    }
}
//---mi cuenta  titulos ---//
%ttl-mycount, .ttl-mycount{
    font: {
        family: f(roboto);
        size: 30px;
        weight:600;
        line-height: 39px;
    }
}

//---mi cuenta modal iniciar sesion ---//
%ttl-modal-mycount-session, .ttl-modal-mycount-session{
    font: {
        family: f(roboto);
        size: 15px;
        weight:500;
        line-height: 21px;
    }
}
