/*_________________________ Grid _________________________ */
.grid {
	$gutter: 30px;
	$gutter-pad: $gutter/2;
    $pad: $gutter/3;
	$gutter-sm: 30px;

	&__row {
		position:relative;
		width: 100%;
		min-height: 1px;
	}
	&__container {
		position:relative;
		padding: 0 20px;
		display: flex;
		flex-wrap: wrap;
		margin: 30px auto 60px auto;
		// margin-left: auto;
		// margin-right: auto;
		max-width: $container-max-width;
		width: 100%;
	}
	&__col {

		&-1-1,
		&-1-2,
		&-1-3,
		&-1-4,
		&-1-5 {
			position:relative;
		}
		&-1-1 {
			width: 100%;
		}

		&-1-2 {
			@include binary-column-maker($gutter:$gutter);

			@include under(sm) {
				@include binary-column-padding($reset:true);
				width: 100%;
			}
			&--application {
				padding-left: #{$gutter / 2};
				padding-right: #{$gutter / 2};
				@include under(md) {
					padding-left: $gutter !important;
					padding-right: $gutter !important;
					width: 100%;
					&:nth-of-type(odd) {
						margin-top: 10%;
					}

				}
				@include under(md) {
					padding-left: 5px !important;
					padding-right: 5px !important;
				}
			}
		}

		&-1-3{
			@include ternary-column-maker($gutter:$gutter);
			@include under(sm) {
				@include ternary-column-padding($reset:true);
				width: 100%;
			}

			&--products{
				@include under(sm) {
					@include ternary-column-padding($reset:true);
					@include binary-column-maker($gutter:$gutter);
				}
				@include under(xs) {
					@include binary-column-padding($reset:true);
					width: 100%;
				}
			}
		}

		&-1-4 {
			@include cuaternary-column-maker($gutter:$gutter);

			@include under(md) {
				@include cuaternary-column-padding($reset:true);
				@include binary-column-maker($gutter:$gutter);
			}

			@include under(sm) {
				@include binary-column-padding($reset:true);
				width: 100%;
			}
			&--footer {
				@include under(md) {
					@include binary-column-padding($reset:true);
					width: 100%;
				}
			}
		}
		&-1-5 {
			width: calc(100% / 5);
			@include pentary-column-maker($gutter:$gutter);
			@include under(md) {
				width: calc(100% / 4);
				@include pentary-column-padding($reset:true);
				@include cuaternary-column-maker($gutter:$gutter);
			}
			@include under(sm) {
				width: calc(100% / 3);

				@include cuaternary-column-padding($reset:true);
				@include ternary-column-maker($gutter:$gutter);
			}
			@include under(xs) {
				width: calc(100% / 2);

				@include ternary-column-padding($reset:true);
				@include binary-column-maker($gutter:$gutter);
			}
		}
	}

	&__box {
		position:relative;
		width: 100%;
	}
}


/*Grid Debug*/

.debug {
	.grid {
		&__row {
			position:relative;
			background-color: c();
			min-height: 200px;
		}
		&__container {
			position:relative;
			background-color: c(gold);
			min-height: 100px;
			margin-bottom: 20px;
		}
		&__col-1-2,
		&__col-1-3,
		&__col-1-4 {
			position:relative;
			margin-bottom: 10px;
			display: flex;
			&:nth-of-type(even) {
	 			background-color: c(ocher);
	 			min-height: 100px;
			}
			&:nth-of-type(odd) {
				background-color: c(beige);
	 			min-height: 100px;
			}
			&--contain-fixed {
				background-color: c(dark);
			}
		}

		&__box {
			position: relative;
			background-color: rgba(7,7,7,0.7);
			min-height: 50px;
		}

	}

}
