.address {
	width: 100%;
	&__title {
		@extend %ttl-show-product;
		color: c(black);
		padding: 20px 0px;
	}
	&__quote {
		@extend %paragraph-newsletter;
		color: c(grey);
		font-weight: lighter;
		margin-bottom: 10px;
	}
	&__subtitle {
		font-family: f(roboto);
		font-size: 22px;
		color: c(black);
		text-transform: uppercase;
		padding-bottom: 35px;
	}
	&__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__col-1-3 {
		padding: 45px 0px 40px 0px;
		width: calc((100% / 3) - 30px);
		border-bottom: 1px solid c(light-grey);
		&--no-padding {
			padding: 0px;
		}
		@include under (sm) {
			width: 100%;
		}
	}
	&__link {
		font-family: f(roboto);
		font-size: 16px;
		transition: color .25s ease;
		&:hover {
			color: c(blue-green);
		}
	}
	&__empty {
		@extend %paragraph-newsletter;
		color: c(black-light);
		font-weight: normal;
		margin-bottom: 10px;
	}
	&__shipping {
		@extend %paragraph-newsletter;
		line-height: 19px;
		color: c(black-light);
		font-weight: normal;
	}
	&__input {
		margin: 10px 0px;
		&-container {
			margin-top: 10px;
			width: 100%;
		}
	}
	&__required {
		color: red;
		margin-left: 5px;
	}
	&__form {
		@extend .cards__form;
		width: 100% !important;
		align-items: flex-end !important;
	}
}
