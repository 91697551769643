.preview-modal {
	&__title {
		display: block;
	    width: 100%;
	    text-align: center;
	    margin: 5% 0px;
		@extend %ttl-show-product;
	}
	&__info {
		&-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			align-items: center;
			padding-bottom: 5%;
		}
		&-title {
			@extend %ttl-footer-post;
		}
		&-item {
			@extend %sbttl-products-related;
		}
	}
	&__close {
		position: absolute;
	    right: 40px;
	    font-size: 30px;
	    top: 20px;
	    cursor: pointer;
	}
}
