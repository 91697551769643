.search{
	&-empty {
		&__icon {
			width: 100%;
			height: 72px;
			margin: 5% auto;
			max-width: 73px;
			@include under(xs) {
				margin: 10% auto;
			}
		}
		&__text {
			text-align: center;
			font-family: f(roboto);
			font-size: 32px;
			margin-bottom: 20px;
			font-weight: 600;
			width: 100%;
			color: c(black-light);
			line-height: 41px;
		}
		&__button {
			display: inline-block;
			transition: color 0s linear;
			font-family: f(roboto);
			font-size: 13px;
			text-transform: uppercase;
			color: c(white);
			background-color: c(ultralight-grey);
			padding: 9px 28px;
    		line-height: 15px;
			margin: 20px auto 0px auto;
			position: relative;
			cursor: pointer;
			&:before {
				content: "\f104";
			    position: absolute;
			    left: 15px;
			    font-size: 14px;
			    top: 50%;
			    margin-top: -8px;
			    color: c(white);
				font-family: FontAwesome;
			}
			&:hover {
				background-color: c(red);
			}
		}
	}

	&__container{
		margin-bottom: 15px;
		// width: 100%;
		height: auto;
		overflow: hidden;
		&--img {
			object-fit: cover;
			object-position: center;
			height: 208px;
		}
	}
	&__pipe {
		font-size: 16px;
		margin: 0 5px;
		&:last-of-type {
			display: none;
		}
	}
	&__sbttl {
		@extend %sbttl-product-price;
		margin: 0 0 20px;
		text-transform: uppercase;
		text-align: center;

	}
	&__text {

		display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	    justify-content: center;
		align-items: baseline;
		.ttl-product{
			margin: 15px 0px;
			text-transform: uppercase;
			line-height: 20px;
			&:hover{
				color: #169688;
				text-decoration: underline;
			}
		}
	}


}
