/*%label {
    font-family: f(sans);
    font-size: 12px;
    font-weight: normal;
    color: c();
    text-transform: uppercase;
}

%label-select {
    @extend %label;
    font-family: f(inputs);
    font-size: 16px;
    font-style: italic;
}

%label-footer {
    @extend %title;
    font-size: 13px;
    font-weight: bold;
}

%label-light {
    @extend %label;
    font-size: 11px;
    font-weight: 200;
    text-transform: none;
}*/

//---Home, label newsletter--//
%label-newsletter, .label-newsletter{
    font: {
        family: f(roboto);
        size: 14px;
    }
}

//---show titulos de prueba---//
%label-text-proof, .label-text-proof{
    font: {
        family: f(roboto);
        size: 14px;
        line-height: 19px;
    }
}

//---mi cuenta  titulos de prueba---//
%label-mycount, .label-mycount{
    font: {
        family: f(roboto);
        size: 14px;
        line-height: 14px;
    }
}

//---mi cuenta modal label---//
%label-modal-user, .label-modal-user{
    font: {
        family: f(roboto);
        size: 14px;
    }
    line-height: 22px;
}
