.footer{
	// position: absolute;
	// bottom: 0px;
	// width: 100%;
	// max-height: 950px;
	// height: auto;
	&__logo--img{
		max-width: 350px;
		margin-right: auto;
		@include under(sm){
			width: 50%;
		}
		@include under(sm-smaller){
			width: 100%;
		}
	}
	&__acerca{
		color: c(ultralight-grey);
		letter-spacing: 1px;
		margin-top: 5px;
		margin-bottom: 18px;
		a:hover{
			color: c(black);
		}
	}
	&__contact{
		color: c(black);
		letter-spacing: 1px;
		&--mail a{
			color: c(grey);
			letter-spacing: 1px;
			&:hover{
				color: c(black);
			}
		}
	}
	&__redes{
		@include under(sm){
	    	text-align: center;
	    }
	}
	&__redes span a i{
		background: #464646;
	    color: #FFF;
	    width: 25px;
	    text-align: center;
	    height: 25px;
	    border-radius: 20px;
	    font-weight: 400;
	    margin: 25px 8px;
		font-size: 14px;
		line-height: 24px;
	    &:hover{
	    	background: c(lighter-grey);
	    }
	}
	&__column{

		&--blog--date{
			display: inline-flex;
			a p:first-child{
				color: #169686;
				border: 2px solid #169686;
				width: 50px;
				height: 50px;
				border-radius: 50px;
				font-size: 18px;
				padding: 14px;
				margin: 20px 20px 10px;
			}
			a p:nth-child(2){
				text-align: center;
			}
		}
		&--blog--post{
			width: 100%;
			margin-top: 18px;
			cursor: pointer;
			&:hover{
				color: c(blue-green);
			}

			a h4{
				letter-spacing: .5px;
			}
			h6{
				color: c(ultralight-grey);
				margin-top: 14px;
			}
		}

		h3{
			color: #169688;
			text-transform: uppercase;
			border-bottom: 1px solid #ddd;
			padding-bottom: 12px;
		}
		&--product{
			margin: 35px 0px;
			border-bottom: 1px solid #DDD;
    		padding-bottom: 35px;

			&--img{

				display: block;
				width: 100%;
			    height: 100%;
				object-fit: cover;
				&-container{
					height: 72px;
					width: 72px;
					overflow: hidden;
					border: 1px solid #DDD;
					padding: 5px;
					display: inline-block;
				}

			}

			&--description{
				padding-left: 15px;
				float: right;
		    	display: inline-block;
		    	width: calc(100% - 72px);
		    	a h4:hover{
		    		color: c(grey);
		    	}
		    	&--price{
		    		color: #169688;
		    	}
			}
		}
		&--faq{
			margin: 5px;
			color: c(grey);
			li{
				border-bottom: 1px solid #DDD;
				padding: 20px 3px;
				&:last-child{
					border-bottom: none;
				}
			}
			li a:hover{
				color: c(black);
			}
			li a i{
				color: #4286c4;
				margin-right: 10px;
			}
		}
	}
	&__copy{
		width: 100%;
		border-top: 2px solid #DDD;
		padding-top: 40px;
		margin-top: 20px;
		display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: center;
		color: c(grey);
		a, i {
			color: c(blue-green);
		}
		span img{
			width: 30px;
	    	display: inline;
			@include under(sm) {
				width: 100%;
				max-width: 30px;
				margin-right: auto;
				margin-left: auto;
			}
		}
		&--terms {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			width: 30%;
			@include under(md) {
				width: 40%;
			}
			@include under(sm) {
				width: 100%;
			}
		}
	}

}
