.profile {
	&__count {
		@extend %paragraph-newsletter;
		line-height: 19px;
		font-weight: lighter;
		color: c(black-light);
		b {
			font-weight: bold;
		}
		a {
			color: c(black);
			transition: color .25s ease;
			&:hover {
				color: c(blue-green);
			}
		}
	}
	&__menu {
		&-container {
			width: 100%;
		}
		&-list {
			list-style: disc;
			padding-left: 13px;
			margin-bottom: 10px;
		}
		&-items {
			@extend %menu-items;
			line-height: 19px;
			text-transform: initial;
			font-family: f(roboto);
			font-weight: normal;
			transition: color .25s ease;
			color: c(black);
			&:hover {
				color: c(blue-green);
				text-decoration: underline;
			}
		}
	}
	&__account {
		width: 100%;
		margin-bottom: 20px;
		&-title {
			font-family: f(roboto);
			font-size: 21px;
			color: c(black-light);
			width: 100%;
			border-bottom: 1px solid c(lighter-grey);
			margin: 20px 0px;
			display: block;
		}
		&-label {
			@extend %label-modal-user;
			color: c(grey);
			font-weight: lighter;
		}
		&-input {
			@extend %input-newsletter;
			color: c(grey);
		}
		&-button {
			@extend %btn-newsletter;
			background-color: c(blue-green);
			color: c(white);
			padding: 6px 18px;
			text-transform: uppercase;
			font-family: f(roboto);
			cursor: pointer;
		}
		&-link {
			float: right;
			@extend %label-modal-user;
			color: c(grey);
			margin-top: 10px;
			transform: translateY(50%);
			&:hover {
				cursor: pointer;
				text-decoration: underline;
				text-decoration-color: c(grey);
			}
		}
	}
	&__input-container {
		margin: 20px 0px;
	}
	&__button-container {
		display: inline-block;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__1-2 {
		width: calc(50% - 50px);
		margin-bottom: 20px;
		@include under (sm) {
			width: 100%;
		}
	}
	&__logout {
		&-form {
			display: inline-block;
		}
		&-label {
			height: auto;
			padding: 0px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
