.gallery{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: baseline;
	&__container{
		margin-bottom: 15px;
		width: 100%;
		height: auto;
		overflow: hidden;
		&--img {
			object-fit: cover;
			object-position: center;
			height: 208px;
		}
	}

	&__text {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-bottom: 40px;
		display: block;
		.ttl-product{
			margin: 15px 0px;
			text-transform: uppercase;
			&:hover{
				color: #169688;
			}
		}
		.sbttl-product-price {
			margin: 0 0 20px;
		}
		.btn-personalize {
			line-height: 15px;
		    font-size: 11px;
		    padding: 8px 20px;
		    border:2px solid #000;
			display: inline-block;
		    &:hover {
				background-color: #000;
				color: #fff;
				font-weight: 500;
		    }
		}
	}
}

.swiper-button-next, .swiper-button-prev {
	//display:none;
    height: 24px !important;
    margin-top: -12px !important;
    background-size: inherit !important;
}
/*

table {
  width: 100%;
  border-collapse: collapse;
}
tr:nth-of-type(odd) {
  //background: #eee;
}
th {
  //background: #333;
	font: 15px/15px 'Raleway', Arial, Helvetica, sans-serif;
	font-weight: 500;
	letter-spacing: 1px;
	color: #000;
	text-align: center;
	text-transform: uppercase;
}
td, th {
  padding: 6px;
  border: 1px solid #ddd;
  //text-align: left;
}
.products__table{
	vertical-align: middle;

	font: 14px/22px 'Raleway', sans-serif;

	.name{
		display: table-cell;
    	vertical-align: middle;
    	text-align: left;
    	font-weight: 300;
	}
	.price, .total{
		family: f(ralewaRegular);
		font-size:20px;
		font-weight: 700;
		text-align: center;
		font-weight: bold;
	}
}




@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table, thead, tbody, th, td, tr {
		display: block;
	}

	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr { border: 1px solid #ddd; }

	td {
		border: none;
		border-bottom: 1px solid #ddd;
		position: relative;
		padding-left: 50%;
	}

	td:before {
		position: absolute;

		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}





	td:nth-of-type(1):before { content: ""; }
	td:nth-of-type(2):before { content: ""; }
	td:nth-of-type(3):before { content: "Producto"; }
	td:nth-of-type(4):before { content: "Precio"; }
	td:nth-of-type(5):before { content: "Total"; }
}*/













.carrito {
	&__img {
	    width:  100%;
	    max-width: 250px;
	    margin:  0 auto;
	    object-fit: contain;
	    height: 250px;
	}
	&__vacio {
		margin-bottom: 50px;
		&--ttl {
			font-family: 'ralewayRegular';
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 0;
			line-height: 1.3;
			font-size: 30px;
			text-align: center;
			margin-bottom: 10px;
			@include under(md) {
				margin-top: 30px;
			}
		}
		&--sbttl {
			font: {
					family: f(roboto);
					size: 13px;
				}
				margin-bottom: 20px;
				color: #767676;
				line-height: 1.8;
				text-align: center;
		}
		.btn-Car {
			width: 215px;
			display: block;
			margin: 0 auto;
		}

	}
	&__Table {
		//border: 1px solid #ddd;
		max-width: calc(100% - 25px);
		margin: 0 auto;
		width: 100%;
		@include under(sm) {
			max-width: calc(100% - 15px);
		}
		&--img {
			width: 45%;
			padding: 25px 16px;
		}
		&--product{}
		table { width: 100%; }
		tr.ttl--shop-carrito th{
			padding: 10px;
		}
		td, th {
			padding: 25px 25px;
			border-width: 1px 0px 1px 0px;
			border: 1px solid #ddd;
			border-collapse: collapse;
		}
		td {
				vertical-align: middle;
		}
		td:last-child {
				font-size: 0.95em;
				line-height: 1.4;
				text-align: left;
		}
		th { //background-color: #efefef;
		}
		tr:nth-child(2n) {
				background-color: white;
		}
		// tr:nth-child(2n+1) { background-color: #fafafa; }
	}



	&__desc{
		&--col-1-2{
			width: 50%;
			float: left;
			padding-left: 15px;
			padding-right: 15px;
			h3{
				font: {
					family: f(roboto);
					size: 16px;
				}
		    color: #000;
		    text-transform: uppercase;
		    letter-spacing: 0px;
		    border-bottom: 2px solid #000;
		    padding-bottom: 10px;
		    text-decoration: none;
		    margin-top: 40px;
			}
			.btn-Car{
				float: right;
			}
		}
		&--checkout_coupon{
			margin-top: 20px;
			.cupon{
				    width: 60%;
			}
			.btn-Car{
				    width: 38% !important;
					margin-top: 20px;
			}
		}
		&--tableTotal{
			width: 100%;
			margin: 0 0 20px;
			th, td{
				font: {
						family: f(roboto);
						size: 14px;
						weight: 500;
				}
				color: #767676;
				letter-spacing: 1px;
				text-transform: uppercase;
				vertical-align: bottom;
				padding: 20px 0px;
				text-align: left;
			}
			tr{
				border-bottom: 1px solid #000;
			}
		}
	}

	&__link {
		cursor: pointer;
		text-align: center;
		height: 28px;
		padding-top: 10px;
		font-size: 12px;
		text-decoration: underline;
		font-weight: normal;
	}

	&__quantity-td {
		min-height: 122px;
		position: relative;
		top: 14px;
	}

	&__preview-modal {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999999;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 45px;

		&-main-container {
			position: fixed;
			left: 0;
			right: 0;
			background-color: c(white);
			padding: 0;
			max-height: 100%;
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			overflow-y: auto;
			border-radius: 2px;
			padding: 10px 20px;
			will-change: top, opacity;
		}
	}
	&__title {
		margin-bottom: 30px;
		@include under(md) {
			margin-top: 30px;
		}
 	}
}

















	@media screen and (max-width: 768px) {
		.carrito{
			&__Table{
				table, tr, td {
						display: block;
				}
				tr.ttl--carrito-product{
					border-width: 1px 0px 1px 0px;
					border: 1px solid #ddd;
					// margin:10px 0px;

				}
				td, th {
					padding: 15px 15px;
					border-width: 1px 0px 1px 0px;
					border: 0px solid #ddd;
				}
				tr:first-child { display: none; }

				td:first-child {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 150px;
				}
				td.btn-delete-car{
					    margin: 0 auto 0 0;
				}
				tr {
						padding: 10px 0;
						position: relative;
				}

				td:not(:first-child) {
						clear: both;
						margin-left: 150px;
						padding: 4px 20px 4px 20px;
						position: relative;
						text-align: left;
				}

				td:not(:first-child):before {
						color: #bbb;
						content: '';
						display: block;
						left: 0;
						position: absolute;
				}
				.btn-delete-car{
					margin: 0px;
				}
			}




			&__desc{
				&--col-1-2{
					width: 100%;
					padding-left: 15px;
    			padding-right: 15px;
				}
				&--checkout_coupon .btn-Car{
					padding: 0px;
				}
			}
		}

			// td:nth-child(2):before { content: '&nbsp'; }
			// td:nth-child(3):before { content: '&nbsp'; }
			// td:nth-child(4):before { content: 'Producto:'; }
			// td:nth-child(5):before { content: 'Precio:'; }
			// td:nth-child(6):before { content: 'Total:'; }
	}
