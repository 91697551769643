.error {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: f(roboto);
	&__number {
	font-size: 200px;
	font-weight: 500;
	line-height: 200px;
	color: c(black-grey);
	margin: 27px 0px;
	}
	&__menssage {
		font-size: 36px;
		font-weight: 600;
		line-height: 46.8px;
		color: c(black-grey);
		text-transform: uppercase;
	}
	&__line {
	width: 50px;
	margin: 20px auto;
	display: block;
	height: 3px;
	background: #e6e6e6;
	border: none;
	}
	&__text {
		font-size: 13px;
		color: c(grey);
		line-height: 23.4px;
		margin-bottom: 20px;
	}
	&__search {
		display: flex;
		flex-direction: row;
		input {
			margin: 0px 18px;
			width: 270px;
		}
		button {
			text-transform: uppercase;
			background-color: c(lighten-grey);
			color: c(white);
			padding: 9px 28px;
			border: none;
			cursor: pointer;
			&:hover {
				background-color: c(blue-green);
			}
		}
	}
	&__btn-return {
		text-transform: uppercase;
		background-color: c(blue-green);
		color: c(white);
		padding: 12px 28px;
		border: none;
		cursor: pointer;
		margin: 15px 0px;
	}
}
