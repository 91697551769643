.single {
	&__gallery {

		&-container {
			width: 100%;
			max-width: 465px;
			margin: 0 auto;
			position: fixed;
			top: 150px;
			left: auto;
			right: auto;
		}
		&-ttlResponsive {
			display: none;
			@include under (md) {
				display: block;
				//Faltan estilos del título
			}
		}
	}
}
