.billings {
	&__title {
		@extend %ttl-show-product;
		color: c(black);
		padding: 20px 0px;
	}
	&__container {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	&__two-inputs {
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include under(xs) {
			flex-wrap: wrap;
		}
		&--container {
		    width: calc((100%/2) - 20px);
			@include under(xs) {
				width: 100%;
			}
		}
	}
	&__labels {
		font-family: 'Cabin';
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 5px;
		color: c(grey);
		@include under(xs) {
			font-size: 13px;
		}
	}
	&__inputs {
		@include under(xs) {
			font-size: 13px;
		}
		background-color: c(white);
		border: 1px solid c(lighten-grey);
		border-radius: initial;
		padding: 10px;
		height: 39px;
		font-weight: lighter;
		font-size: 14px;
		margin: 5px 0px 15px 0px;
	}
	&__button {
		@extend %btn-add-car;
		background-color: c(blue-green);
		color: c(white);
		border: none;
		cursor: pointer;
		height: 40px;
		font-weight: normal;
		text-align: center;
		padding: 6px 40px;
		&-container {
			margin-top: 20px;
			width: 100%;
		}
		width: auto;
    	margin-left: auto;
	}
	&__checkboxes {
		@extend .checkout__radiobuttons;
		&--align {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: baseline;
			align-items: baseline;
			width: 100%;
		}
	}
	&--width {
		width: 100%;
		margin: 5px 0px;
	}
	&__terms {
		color: c(black);
		&:hover {
			text-decoration: underline;
		}
	}
}
