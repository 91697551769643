%button {

}

//---Home, boton slider---//
%btn-slider, .btn-slider{
    font: {
        family: f(robotoMedium);
        size: 14px;
    }
}

//---Home, boton newsletter---//
%btn-newsletter, .btn-newsletter{
    font: {
        family: f(robotoMedium);
        size: 12px;
    }
    text-transform: uppercase;
}

//---index, boton personalizar de producto---//
%btn-personalize, .btn-personalize{
    font: {
        family: f(roboto);
        size: 11px;
        weight: 500;
    }
    text-transform: uppercase;
}

//---show boton de añadir a carrito---//
%btn-add-car, .btn-add-car{
    font: {
        family: f(roboto);
        size: 13px;
    }
    text-transform: uppercase;
}



%btn-price-car, .btn-price-car{
    font: {
        family: f(roboto);
        size: 20px;
        weight: 700;
    }
}
//boton carrito
%btn-Car, .btn-Car{
  font: {
      family: f(roboto);
      size: 13px;
  }
  background-color: #169688;
  color: #fff;
  line-height: 40px;
  height: 40px;
  padding: 0 28px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  text-align: center;
  &:hover, &:focus{
    opacity: 0.9;
    color: #fff;
  }
}

%btn-delete-car, .btn-delete-car{
    font: {
        family: f(roboto);
        size: 10px;

        weight: 700;
    }
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: transparent;
    border-color: #000;
    color: #000;
    border-width: 1px;
    margin: 0 auto;
    display: block;
    padding: 8px 10px;
    &:hover{
      background-color: c(black);
      color: c(white);
      border-color: c(white);
    }
}


//---mi cuenta  boton acceder--//
%btn-access, .btn-access{
    font: {
        family: f(roboto);
        size: 13px;
    }
    text-transform: uppercase;
}

//---mi cuenta modal boton acceder--//
%btn-modal-access, .btn-modal-access{
    font: {
        family: f(roboto);
        size: 13px;
    }
    text-transform: uppercase;
    line-height: 15px;
}
