.orders {
	&__table {
		width: 100%;
	}
	&__title {
		font-family: 'Raleway';
		font-size: 15px;
		text-transform: uppercase;
		color: #252525;
		vertical-align: middle;
		letter-spacing: 1px;
		padding: 22px 0 !important;
		line-height: 1.42857143;
		text-align: left;
	}
	&__content {
		line-height: 1.42857143;
	    vertical-align: top;
	    border-top: 1px solid #dddddd;
	    font-size: 15px;
	    font-family: 'Raleway';
	    vertical-align: middle;
		padding: 8px;
		text-align: left;
	}
	&__empty {
		@extend .orders__content;
		text-align: center;
		font-weight: bold;
		border-top: none;
	}
	&__button {
		@extend %btn-add-car;
		background-color: c(blue-green);
		color: c(white);
		border: none;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		text-align: center;
		padding: 6px 18px;
	}
}
