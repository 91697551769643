.faq {
	&__title {
		@extend %sbttl-faq,;
		width: 100%;
    	color: c(grey);
	}
	&__content {
		width: 100%;
		@extend %paragraph-faq;
		margin-bottom: 20px;
    	color: c(grey);
		b,strong {
			font-weight: bold;
		}
		i,em {
			font-style: italic;
		}
	}
}
