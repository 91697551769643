/*%paragraph {
    font-family: f(sans);
    font-size: 22px;
    font-weight: normal;
    color: c();
    line-height: lh(lg);
}

%paragraph-small {
    @extend %paragraph;
    font-size: 15px;
    font-weight: 200;
    color: c();
    line-height: lh(md);
}

*/

//---HOME - paragraph-newsletter, SHOW - show paragraphs de conteo de palabras---//
%paragraph-newsletter, .paragraph-newsletter{
    font: {
        family: f(roboto);
        size: 13px;
    }
    line-height: 23px;
}

%footer-description, .footer-description{
    font: {
        family: f(roboto);
        size: 14px;
        text-transform: uppercase;
    }
}

//---Home, footer precio---//
%footer-price, .footer-price{
    font: {
        family: f(roboto);
        size: 16px;
        weight: 500;
    }
}

//---Home, footer fecha de post recientes---//
%footer-date, .footer-date{
    font: {
        family: f(roboto);
        size: 18px;
        weight: 300;
    }
}

//---Home, footer fecha de post recientes---//
%footer-date-month, .footer-date-month{
    font: {
        family: f(roboto);
        size: 12px;
        weight: 300;
    }
}

//---Home, footer by autor---//
%footer-post-author, .footer-post-author{
    font: {
        family: f(roboto);
        size: 13px;
    }
}

//---Home, copy right--//
%footer-copyright, .footer-copyright{
    font: {
        family: f(roboto);
        size: 12px;
    }
}

//---Home, terminos y condiciones--//
%footer-terms, .footer-terms{
    font: {
        family: f(roboto);
        size: 12px;
    }
}

//---FAQ paragraphs ---//
%paragraph-faq, .paragraph-faq{
    font: {
        family: f(roboto);
        size: 13px;
    }
	line-height: 23px;
}

//---Blog paragraphs ---//
%paragraph-blog, .paragraph-blog{
    font: {
        family: f(roboto);
        size: 13px;
    }
	line-height: 23px;
}

%paragraph-blog-date, .paragraph-blog-date{
    font: {
        family: f(roboto);
        size: 12px;
    }
	line-height: 24px;
}

%paragraph-blog, .paragraph-blog{
    font: {
        family: f(roboto);
        size: 13px;
    }
	line-height: 24px;
}
//---mi cuenta paragraphs de registrarse---//
%paragraph-mycount, .paragraph-mycount{
    font: {
        family: f(roboto);
        size: 13px;
    }
	line-height: 23px;
}
