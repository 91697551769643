// header responsive
.header-mobile {
	&__container {
		@include over(md) { display: none; }
		position: fixed;
		z-index: 9999;
		top: 0;
		width: 100%;
		height: 50px;
		background-color: c(white);
		display: flex;
		flex-direction: row;
		border-bottom: solid 2px c(lighter-grey);
	}
	&__burger {
		width: calc(100%/3);
		display:flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		left: 15px;
		cursor: pointer;
		i {
			font-size: 20px;
			color: c(gray);
		}
	}
	&__image {
		width: calc(100%/3);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		img {
			width: 170px;
			height: auto;
		}
	}
	&__buy {
		width: calc(100%/3);
		display:flex;
		justify-content: flex-end;
		align-items: center;
		right: 15px;

		&--price {
		@include under(xs) {
			display: none;
		}
		width: 20px;
		height: 20px;
		font-size: 13px;
		background-color:c(lighter-grey);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		line-height: 10px;
		font-family: f(roboto);
		position: absolute;
		top: 5px;
    	right: 15px;
		}
		&--bag {
			@include under(xs) {
				right: 5px;
			}
			font-size: 20px;
			color: c(blue-green);
			cursor: pointer;
			position: absolute;
			right: 35px;
		}
		&--search {
			&:hover .elements__search--tooltip {
				visibility: visible;
			}
			@include under(xs) {
				right: 35px;
			}
			width: 25px;
			height: 25px;
			font-size: 13px;
			background-color:c(light-grey);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 25px;
			cursor: pointer;
			position: absolute;
			right: 70px;
			i {
				color: c(white);
				font-size: 12px;
			}
		}
	}
}
