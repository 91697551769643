$colors: (
	white: #FFFFFF,
	blue-green: #169688,
	black: #000,
	light-grey:#C1C1C1,
	lighter-grey:#E4E4E4,
	black-light:#333,
	grey:#767676,
	background-grey:#f5f5f5,
	black-dark:#252525,
	lighten-grey:#D2D2D2,
	ultralight-grey: #AAA,
	black-grey:  #2c2c2c,
	dark-green: #d6e9c6,
	light-green: #dff0d8,
	dark-red: #ebccd1,
	light-red: #f2dede,
	yellow: rgb(225, 237, 0),
	red: #e5534c,
	blue: #4286C4
);

@function c($color:base) {
    @return map-get($colors, $color);
}

$colorForeground: c();
$colorBackground: c(white);
$colorFeatured: c(azul);
$colorError: #8A1010;
