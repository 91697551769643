.auth{
	&__title{
		color:c(black);
		font-size: 30px;
		font-weight: 600;
		font-family:f(roboto);
	    text-transform: uppercase;
		line-height: 1.3;
	}

	&__form{

		&--titleInput{
			color: #767676;
			font-size: 14px;
			font-family:f(roboto);
			line-height: 22px;
    		margin: 10px 0px;
		}

	 	&--submit{
			background-color: #169688;
		    color: #FFFFFF;
		    font-size: 13px;
		    font-family:f(roboto);
		    padding: 6px 18px;
			text-transform: uppercase;
		    cursor: pointer;
		    margin: 3% 0%;
		    width: auto;
		    height: 27px;
		    border: none;
	 	}

		&--input {
			margin: 0px 0px 10px 0px;
		}
	}
	&__remember{
		font-size: 14px;
	    font-family:f(roboto);
	    color: #767676;
	}
	&__text {
		font-family:f(roboto);
		color:c(grey);
		font-size:13px;
		line-height: 23.4px;
		margin-bottom: 20px;
	}
	&__restore-btn {
		height: 40px;
		width: 100%;
		max-width: 258px;
		padding: 10px 20px 10px;
		margin: 22px 0px !important;
	}
	&__links{
			&:hover{
				color: c(blue-green);
			}
		&--password{
			font-family:f(roboto);
			color:c(black);
			font-size:13px;
			line-height: 70px;
			&:hover {
				color: c(blue-green);
			}
		}
	}
	&__register {
		&--text {
			color: rgb(118, 118, 118);
			font-family:f(roboto);
			font-size: 13px;
			line-height: 23.4px;
		}
	}
}

.google-button {
	height: 40px;
	border-width: 0;
	background: white;
	color: #737373;
	border-radius: 5px;
	white-space: nowrap;
	box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.05);
	transition-property: background-color, box-shadow;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
	padding: 0;
	margin-left: 30px;
	
	&:focus,
	&:hover {
	  box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
	}
	
	&:active {
	  background-color: #e5e5e5;
	  box-shadow: none;
	  transition-duration: 10ms;
	}
  }
	  
  .google-button__icon {
	display: inline-block;
	vertical-align: middle;
	margin: 8px 0 8px 8px;
	width: 18px;
	height: 18px;
	box-sizing: border-box;
  }
  
  .google-button__icon--plus {
	width: 27px;
  }
  
  .google-button__text {
	display: inline-block;
	vertical-align: middle;
	padding: 0 24px;
	font-size: 14px;
	font-weight: bold;
	font-family: 'Roboto',arial,sans-serif;
  }
