.thankyou-page {
	&__title{
		@extend %ttl-mycount;
		width: 100%;
		text-align: center;
		line-height: 39px;
		@include under(xs) {
			margin: 35px 0px;
		}
	}
	&__payment {
		&-list {
			width: 100%;
		    display: flex;
		    flex-direction: column;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: baseline;
		    padding: 20px 0px;
		}
		&-items {
			@extend %label-mycount;
			font-size: 13px;
			color: c(black-light);
			line-height: 24px;
			&--bold {
				font-weight: bold;
			}
		}
	}
	&__spei {
		@extend %paragraph-blog;
		color: c(grey);
	}
	&__section {
		&-title {
			@extend %ttl-shop-carrito;
			font-weight: 400;
			line-height: 40px;
			border-bottom: 2px solid c(black);
			margin-bottom: 10px;
		}
		width: 100%;
		padding: 30px 0px;
		&-label {
			@extend %ttl-shop-carrito;
			color: c(black-dark);
			line-height: 21px;
			&--small {
				font-size: 13px;
			}
			&--bold {
				font-weight: bold;
			}
			&--lowercase{
				text-transform: lowercase;
			}
			@include under(xs) {
				font-size: 12px;
				letter-spacing: 0;
			}
		}
		&-row {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0px;
			border-bottom: 1px solid c(lighten-grey);
			&--wrap {
				flex-wrap: wrap;
			}
		}
	}
	&__address {
		padding-top: 30px;
		width: calc(50% - 10px);
		&-title {
			font-size: 24px;
			color: c(black-light) !important;
		}
		&-list {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: baseline;
			width: 100%;
		}
		&-item {
			@extend %paragraph-newsletter;
		}
		@include under(xs){
			width: 100%;
		}
	}
	&__invoice {
		padding: 30px 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: baseline;
		align-items: center;
		flex-wrap: wrap;
		&-button {
			@extend %btn-add-car;
			background-color: c(blue-green);
			color: c(white);
			border: none;
			cursor: pointer;
			height: 40px;
			line-height: 40px;
			padding: 0px 28px;
			text-align: center;
			margin-top: 10px;
		}
		@include under(xs){
			width: 100%;
		}
	}
}
