.pages {
	&__container {
		width: 100%;
		padding: 0px 15px;
		margin-top: 30px;
    	margin-bottom: 60px;
	}
	&__title {
		// @extend %title;
	}
	&__content {
		i { // @extend %paragraph; font-style: italic !important;
		}
		b { // @extend %paragraph; font-weight: bold !important;
		}
		a { // @extend %paragraph; text-decoration: underline !important;
		}
		li { // @extend %paragraph; margin-bottom: 5px !important;
		}
		ul, ol {
			// @extend %paragraph;
			list-style-type: decimal;
			margin: 15px 0 !important;
			text-align: left !important;
			letter-spacing: 0 !important;
		}
		div, p, span {
			// @extend %paragraph;
			font-size: 18px;
		}
		* {
			// @extend %paragraph;
		}
	}
}
