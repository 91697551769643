.terms {
	font-family: f(roboto)!important;
	color: c(grey);
	font-size: 13px;
	line-height: 23.4px;
    &__title{
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 30px;
    }
	&__link {
		cursor: pointer;
		color: c(black);
		margin: 42px 0px;
		&:hover {
			color: c(blue-green);
		}
	}
    &__content{
		line-height: 18.5714px;
		b,strong {
			font-weight: bold;
		}
		i,em {
			font-style: italic;
		}
    }
}
