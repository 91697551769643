
.swiper-container {
        width: 100%;
        height: 300px;
        margin: 20px auto;
}

.swiper-slide-container {
  text-align: center;
    font-size: 18px;
    background: #fff;
  height:100%;
  max-width: 600px;
  margin:auto;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
}

.gallery-top {
        height: 80%;
        width: 100%;
}
.gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
        width: 20%;
        height: 100%;
        opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
        opacity: 1;
}
