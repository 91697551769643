.home{
	&__categories{
		&--container{
			height: 250px;
    		background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 30px;
	    		&--link{
				color: #FF6601;
				padding: 0 0 0 4%;
				&:hover{
					text-decoration: underline;
					color: c(blue-green);
				}
			}
		}
		h4{
			padding: 25% 4% 2%;
			width: 100%;
			word-wrap: break-word;
		}
	}
	&__newsletter{
		&--ttl{
			margin-bottom: 10px;
		}
		&--paragraph, &--form--label{
			color: c(grey);
		}
		&--input{
			&--mail{
				margin-top: 15px;
			}
			&--submit{
				background: c(blue-green);
			    width: auto;
			    float: right;
			    margin-top: 10px;
			    color: c(white);
			    letter-spacing: 1px;
				font-weight: normal;
				cursor: pointer;
			}
		}
	}
	&__banner{
		img{
			width: 100%;
			height: 100%;
		}
		&--ttl{
			line-height: 25px;
		    width: 100%;
		    text-align: center;
			text-transform: uppercase;
		    @include center('xy');
		    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		    @include under(sm-smaller){
		    	display: none;
		    }
		    @include under(xs){
		    	font-size: 2em;
		    }
		}
		&--sbttl{
			width: 100%;
		    text-align: center;
		    display: block;
		    margin-top: 7%;
			text-transform: uppercase;
		   	@include center('y');
		    color: #FFF;
		    letter-spacing: 4px;
		    @include under(sm-smaller){
		    	letter-spacing: 0px;
		    	color: c(black);
				max-width: 275px;
				margin-top: 0px;
				margin-left: auto;
				margin-right: auto;
				@include center('xy');
		    }
			@include under(xs) {
				display: none;
			}
		}
		&-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

	}
	&__post{
		&--blog{
			&--link{

			}
			&--bg{
				height: 280px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				@include under(sm) {
					margin-bottom: 35px;
				}
			}
			&--title{
				color: #FFF;
				padding: 3% 0 0 3%;
				text-transform: uppercase;
				font-weight: 600;
				width: 100%;
				max-width: 210px;
				margin-right: auto;
			}
		}
	}
	&__slider {
		&__container {
			margin: 0 auto;
			position: relative;
			overflow: hidden;
			list-style: none;
			padding: 0;
			z-index: 0;
			height: 100%;
			&:hover {
				.home__button {
					visibility: visible;
					opacity: 1;
				}
			}
		}
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
		&-text-container {
			left: 25%;
			right: 25%;
			top: 125px;
			position: absolute;
			z-index: 5;
			opacity: 1;
			@include under(sm) {
				top: 10%;
			}
		}
		&-title {
			font-family: f(benchNine);
			color: c(white);
    		font-size: 88px;
			font-weight: 400;
			width: 100%;
			max-width: 492px;
			margin-right: auto;
			margin-left: auto;
			text-transform: uppercase;
			min-height: 135px;
			max-height: 135px;
			line-height: 68px;
			overflow: auto;
			@include under(md) {
				font-size: 45px;
				min-height: 100px;
				max-height: 100px;
				line-height: 50px;
			}
			@include under(sm) {
				min-height: 66px;
    			max-height: 66px;
				font-size: 44px;
    			line-height: 34px;
			}
			@include under(sm-smaller) {
				font-size: 35px;
				line-height: 27px;
				min-height: 52px;
    			max-height: 52px;
			}
			@include under(xs) {
				font-size: 22px;
				line-height: 17px;
				min-height: 35px;
				max-height: 35px;
			}
		}
		&-subtitle {
			color: c(yellow);
			font-family: f(robotoSlab);
			font-size: 19px;
			text-align: center;
			width: 100%;
			max-width: 302px;
			font-weight: 400;
			line-height: 19px;
			margin: 20px auto;
			min-height: 96px;
			max-height: 96px;
			overflow: auto;
			@include under(md) {
				margin: 10px auto;
				min-height: 60px;
				max-height: 60px;
			}
			@include under(sm) {
				min-height: 43px;
    			max-height: 43px;
				font-size: 23px;
				line-height: 20px;
				margin: 15px auto;
			}
			@include under(sm-smaller) {
				min-height: 34px;
    			max-height: 34px;
				font-size: 20px;
				line-height: 17px;
				margin: 10px auto;
			}
			@include under(xs) {
				font-size: 17px;
				line-height: 15px;
				min-height: 27px;
				max-height: 27px;
				margin: 7px auto;
			}
		}
		&-button {
			width: auto;
			font-size: 14px;
    		line-height: 14px;
    		font-weight: 500;
			font-family: f(roboto);
			color: c(white);
			background-color: rgba(255, 29, 0, 0.75);
    		padding: 10px 30px;
    		border-color: rgb(0, 0, 0);
			box-sizing: border-box;
    		cursor: pointer;
			text-transform: uppercase;
			margin-top: 30px;
			display: inline-block;
			@include under(md) {
				margin-top: 5px;
			}
			@include under(sm-smaller) {;
				margin-top: 15px;
			}
			@include under(xs) {
				font-size: 12px;
				line-height: 12px;
				margin-top: 0;
				padding: 6px 20px;
			}
		}
	}
	&__button {
		visibility: hidden;
  		opacity: 0;
  		transition: all 0.3s ease-in-out;
		width: 45px !important;
	    height: 45px !important;
	    background-color: transparent;
	    border-radius: 100%;
	    border: 2px solid c(white);
	    text-align: center;
		background-image: none !important;
		@include under(xs) {
			width: 30px !important;
		    height: 30px !important;
		}
		&:before {
			font-family: FontAwesome;
		    font-size: 30px;
		    position: absolute;
		    color: c(white);
		    line-height: 41px;
		    display: block;
		    width: 100%;
		    height: 100%;
			@include under(xs) {
				font-size: 15px;
				line-height: 26px;
			}
		}
		&:hover {
			background-color: c(white);
			&:before {
				color: c(black);
			}
		}
		&--next {
			&:before {
				content: "\f105";
				right: -1px;
			}
		}
		&--prev{
			&:before {
				content: "\f104";
    			left: -1px;
			}
		}
	}
}
