.blog {
	font-family: f(roboto);
	@include under(lg) {
		width: 100%;
	}
	@include under(sm) {
		width: 100%;
	}
	@include under(sm) {
		width: 100%;
	}
	@include under(sm-smaller) {
		width: 100%;
	}
	@include under(xs) {
		width: 100%;
	}
	&__link {
		color:c(blue-green);
		cursor: pointer;
		font-size: 12px;
		font-weight: 600;
		line-height: 15.6px;
		text-transform: uppercase;
		&:hover {
			color:c(black-grey);
		}
	}
	&__ttl {
		color: c(black);
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		margin: 18px 0px 10px 0px;
		text-transform: uppercase;
	}
	&__subttl {
		color: c(grey);
		font-size: 13px;
		font-weight: 700;
		line-height: 23.4px;
	}
	&__text {
		color: c(grey);
		font-size:13px;
		line-height: 23.4px;
	}
	&__comment-title {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 105px 0px 40px 0px;
		&--text {
			font-size: 18px;
			letter-spacing: 1px;
			font-weight: 600;
			color: c(black);
		  	line-height: 20px;
			margin-right: 10px;
		}
		&--line {
		height: 1px;
		background-color: c(lighter-grey);
		width: 965px;
		}
	}
}

.comment {
	&__container {
		width: 100%;
		max-width: 1120px;
		border: solid 1px c(grey);
		height: auto;
		padding: 41px 21px 34px;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		position: relative;
	}
	&__media-container{
		display: flex;
		flex-direction: row;
	}
	&__media {
		width: 100%;
    	// position: relative;
		margin-left: 21px;
		display: flex;
		flex-direction: column;
		&--image {
			width: 80px;
			height: 80px;
			img {
				width: 100%;
				height: auto;
			}
		}
		&--ttl {
			text-decoration: none;
			color: c(black);
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 1px;
			&:hover {
				color: c(grey);
			}
		}
		&--date {
        margin: 22px 0px 7px;
		font-size: 12px;
		color: c(grey);
		}
		&--reply {
			color: c(blue-green);
			font-size: 14px;
			font-weight: 500;
			display: inline-block;
			margin: 15px 0px;
			@include over(xs) {
				position: absolute;
				top: 0;
				right: 0;
				margin: 15px;
			}
			&:hover {
				color: c(grey);
				text-decoration: underline;
			}
		}
		&--line {
			width: 70px;
		    height: 3px;
		    margin-top: 17px;
		    background-color: c(lighter-grey);
		}
	}
	&__text {
		@include under(sm) {
			text-indent: 0px;
		}
		color: c(grey);
		font-size:13px;
		line-height: 23.4px;
		text-indent: 92px;
	}
}
