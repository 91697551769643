//_________________________ Config:Fuentes _________________________ \\

/**
 * Declare fonts using the Mixin
 */

 @mixin declara-font-face2($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
 	@font-face {
 		font-family: '#{$font-family}';
 		src: url('#{$font-filename}.eot');
     	src: url('#{$font-filename}.eot?#iefix') format('embedded-opentype');
         src: url('#{$font-filename}.otf') format('opentype');
         src: url('#{$font-filename}.svg##{$font-filename}') format('svg');
 		src: url('#{$font-filename}.ttf') format('truetype');
 		src: url('#{$font-filename}.woff2') format('woff2');
         src: url('#{$font-filename}.woff') format('woff');
 		font-weight: $font-weight;
 		font-style: $font-style;
 		font-stretch: $font-stretch;
 	}
 }


@include declara-font-face2(BenchNine, '../fonts/BenchNine-Regular');
@include declara-font-face2(Montserrat, '../fonts/Montserrat-Bold', bold);
@include declara-font-face2(Opensans, '../fonts/OpenSans-Bold', bold);

@include declara-font-face2(Raleway, '../fonts/Raleway-Bold', bold);
@include declara-font-face2(Raleway, '../fonts/Raleway-Regular',400);
@include declara-font-face2(Raleway, '../fonts/Raleway-Light', 200);
@include declara-font-face2(Raleway, '../fonts/Raleway-Thin', 200);

@include declara-font-face2(RobotoSlab, '../fonts/RobotoSlab-Regular', 400);
@include declara-font-face2(Roboto, '../fonts/Roboto-Medium', 400);

$fonts:(
	montserrat:	('Montserrat', sans-serif),
	benchNine: ('BenchNine', sans-serif),
	opensans: ('Opensans', sans-serif),
	raleway: ('Raleway', sans-serif),
    cabin: ('Cabin', serif),
	roboto: ('Roboto', sans-serif),
	robotoSlab: ('RobotoSlab', serif),
);

@function f($font) {
	@return map-get($fonts, $font);
}
