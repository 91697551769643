.ml {
	margin-left: 10px;
}

.mr {
	margin-right: 10px;
}
.scroll--top {
	right: 25px;
	bottom: 50px;
	color: c(white);
	cursor: pointer;
	z-index: 1000;
	display: inline-block;
	text-align: center;
	position: fixed;
	font-size: 30px !important;
	font-weight: bold;
	background-color: #565656;
	width: 45px;
	height: 45px;
	border-radius: 100%;
	padding-top: 5px;
	transition: all 0.25s ease;
	&:hover {
		background-color: c(black);
	}
}

.opacity {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 9999999999;
	left: 0;
	display: none;
	transition: transform 0.3s ease-in-out;
}

.main-wrap {
	flex: 1 0 auto;
}

.no-margin {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.checkout__radiobuttons {
	border-radius: 3px !important;
}
