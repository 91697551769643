@charset "UTF-8";
/*===Mazorca Core===*/
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/**
* Basic Styles
* ---------------
* Edítalos o sobreescríbelos
*
*/
/**
 * Super basic stylings
 *
 */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  height: 100%;
}

html, body {
  min-height: 100%;
}

body {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: BlissPro;
}

strong {
  font-weight: bold;
}

img {
  -ms-interpolation-mode: bicubic;
}

.column img, .columns img {
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
}

p em {
  font-style: italic;
}

section, article, aside, header, footer, nav, figure, figcaption {
  display: block;
}

time, mark {
  display: inline-block;
}

/**
 * All helpers use the !important keyword, if ever necessary their
 * output might be overriden with the !important keyword in a
 * property located lower in the cascade.
 *
 */
/**
 *	Helpers Index:
 *		Position
 *		Display
 *		Gutters
 *		Paddings and Margins
 *		JS Classes
 *
 */
/*************************** Position ***************************/
.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed !important;
  max-width: 100% !important;
  width: 100% !important;
  left: 0;
}

.leer-mas__container {
  position: relative;
  overflow: hidden;
}

.flip {
  -moz-transform: rotate(180deg) translateX(-50%) !important;
  -o-transform: rotate(180deg) translateX(-50%) !important;
  -webkit-transform: rotate(180deg) translateX(-50%) !important;
  transform: rotate(180deg) translateX(-50%) !important;
}

.centerXY {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-center {
  text-align: center !important;
}

.flex {
  display: flex !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.top56 {
  top: 56px;
}

.ontop {
  z-index: 99 !important;
}

.z-0 {
  z-index: 0 !important;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.op1 {
  opacity: 1 !important;
}

.tdn {
  text-decoration: none !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

/*************************** Layout ***************************/
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/*************************** Display ***************************/
.d-bl {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

/*************************** Gutters ***************************/
/*************************** Paddings and Margins ***************************/
.p0 {
  padding: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

/*************************** Arrows ***************************/
.icon-arrow-up:after {
  content: '\e801';
}

.icon-arrow-down:after {
  content: '\e800';
}

/*************************** Drags, Selects ***************************/
.undraggable-unselectable-cascading *,
.undraggable-unselectable {
  user-drag: none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.unselectable {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

/*************************** Text ***************************/
.ttu {
  text-transform: uppercase !important;
}

.ttn {
  text-transform: none !important;
}

/*************************** Animations ***************************/
.transition-fast {
  transition: all 0.3s;
}

.transition-slow {
  transition: all 1s;
}

.hover-scale-up:hover {
  transform: scale(1.2) !important;
}

/*************************** JS Classes ***************************/
[v-cloak] {
  opacity: 0;
}

.noScroll_JS, .no-scroll {
  overflow: hidden !important;
}

/*************************** Bootstrap ***************************/
.hidden {
  display: none !important;
}

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  font-size: 10px;
}

body, html {
  height: auto;
}

body:before, body:after, html:before, html:after {
  content: " ";
  display: table;
}

body:after, html:after {
  clear: both;
}

body, html {
  *zoom: 1;
}

body {
  display: flex;
  flex-direction: column;
}

/**
 * Declare fonts using the Mixin
 */
@font-face {
  font-family: "BenchNine";
  src: url("../fonts/BenchNine-Regular.eot");
  src: url("../fonts/BenchNine-Regular.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/BenchNine-Regular.otf") format("opentype");
  src: url("../fonts/BenchNine-Regular.svg#../fonts/BenchNine-Regular") format("svg");
  src: url("../fonts/BenchNine-Regular.ttf") format("truetype");
  src: url("../fonts/BenchNine-Regular.woff2") format("woff2");
  src: url("../fonts/BenchNine-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Montserrat-Bold.otf") format("opentype");
  src: url("../fonts/Montserrat-Bold.svg#../fonts/Montserrat-Bold") format("svg");
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  src: url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Opensans";
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/OpenSans-Bold.otf") format("opentype");
  src: url("../fonts/OpenSans-Bold.svg#../fonts/OpenSans-Bold") format("svg");
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2");
  src: url("../fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Bold.eot");
  src: url("../fonts/Raleway-Bold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Raleway-Bold.otf") format("opentype");
  src: url("../fonts/Raleway-Bold.svg#../fonts/Raleway-Bold") format("svg");
  src: url("../fonts/Raleway-Bold.ttf") format("truetype");
  src: url("../fonts/Raleway-Bold.woff2") format("woff2");
  src: url("../fonts/Raleway-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Regular.eot");
  src: url("../fonts/Raleway-Regular.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Raleway-Regular.otf") format("opentype");
  src: url("../fonts/Raleway-Regular.svg#../fonts/Raleway-Regular") format("svg");
  src: url("../fonts/Raleway-Regular.ttf") format("truetype");
  src: url("../fonts/Raleway-Regular.woff2") format("woff2");
  src: url("../fonts/Raleway-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Light.eot");
  src: url("../fonts/Raleway-Light.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Raleway-Light.otf") format("opentype");
  src: url("../fonts/Raleway-Light.svg#../fonts/Raleway-Light") format("svg");
  src: url("../fonts/Raleway-Light.ttf") format("truetype");
  src: url("../fonts/Raleway-Light.woff2") format("woff2");
  src: url("../fonts/Raleway-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Thin.eot");
  src: url("../fonts/Raleway-Thin.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Raleway-Thin.otf") format("opentype");
  src: url("../fonts/Raleway-Thin.svg#../fonts/Raleway-Thin") format("svg");
  src: url("../fonts/Raleway-Thin.ttf") format("truetype");
  src: url("../fonts/Raleway-Thin.woff2") format("woff2");
  src: url("../fonts/Raleway-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("../fonts/RobotoSlab-Regular.eot");
  src: url("../fonts/RobotoSlab-Regular.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/RobotoSlab-Regular.otf") format("opentype");
  src: url("../fonts/RobotoSlab-Regular.svg#../fonts/RobotoSlab-Regular") format("svg");
  src: url("../fonts/RobotoSlab-Regular.ttf") format("truetype");
  src: url("../fonts/RobotoSlab-Regular.woff2") format("woff2");
  src: url("../fonts/RobotoSlab-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/Roboto-Medium.otf") format("opentype");
  src: url("../fonts/Roboto-Medium.svg#../fonts/Roboto-Medium") format("svg");
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  src: url("../fonts/Roboto-Medium.woff2") format("woff2");
  src: url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

/*
*   The Variables
*   Feel free to play with them!
*/
/*Set to "true" for -webkit-,-moz-appereance:none
                            useful for editing radio buttons amog other things*/
/*similar as above but for selects*/
/*
*   If you need to style basic layout and stuff go to your
*   "modules/_contactForm.scss" or similar file
*/
/*Input Elements' Params*/
input, textarea {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: initial;
  padding: 10px;
  height: 39px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input, textarea {
  width: 100%;
}

textarea {
  resize: vertical;
}

input[type="submit"],
input[type="button"] {
  display: block;
  border-color: #FFFFFF;
}

input[type="date"],
input[type="time"],
input[type="button"] {
  width: auto;
}

input::-webkit-datetime-edit {
  color: black;
}

input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 1;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
  padding: 0;
  height: 1rem;
  width: 1rem;
  vertical-align: text-top;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: black;
}

input[type="radio"] {
  border-radius: 100%;
}

select {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: initial;
  padding: 10px;
  height: 39px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 14px;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

#scss-vars::before {
  content: '{ "breaks": { "xs" : "480" ,  "sm-smaller" : "600" ,  "sm" : "768" ,  "md" : "992" ,  "lg" : "1180" },  "colors": { "white" : "#FFFFFF" ,  "blue-green" : "#169688" ,  "black" : "#000" ,  "light-grey" : "#C1C1C1" ,  "lighter-grey" : "#E4E4E4" ,  "black-light" : "#333" ,  "grey" : "#767676" ,  "background-grey" : "#f5f5f5" ,  "black-dark" : "#252525" ,  "lighten-grey" : "#D2D2D2" ,  "ultralight-grey" : "#AAA" ,  "black-grey" : "#2c2c2c" ,  "dark-green" : "#d6e9c6" ,  "light-green" : "#dff0d8" ,  "dark-red" : "#ebccd1" ,  "light-red" : "#f2dede" ,  "yellow" : "#e1ed00" ,  "red" : "#e5534c" ,  "blue" : "#4286C4" },  "fonts": { "montserrat" : "Montserrat, sans-serif" ,  "benchNine" : "BenchNine, sans-serif" ,  "opensans" : "Opensans, sans-serif" ,  "raleway" : "Raleway, sans-serif" ,  "cabin" : "Cabin, serif" ,  "roboto" : "Roboto, sans-serif" ,  "robotoSlab" : "RobotoSlab, serif" },  "spacings": { "mb" : "(base: 10px)" ,  "pads" : "(p: 10px, pt: 10px, pb: 10px, pl: 10px, pr: 10px, xs: 7px, sm: 16px, md: 18px, gutter: 50px, body: 34px, header: 161px)" ,  "margs" : "(m: 10px, mt: 10px, mb: 10px, ml: 10px, mr: 10px)" ,  "lh" : "(base: 1.2, sm: 15px, md: 20px, lg: 40px)" }}';
  font-size: 0;
  opacity: 0;
}

/*%title {
    font-family: f(sans);
    size: 12px;
    font-weight: normal;
    color: c();
    text-transform: uppercase;
}

%title-description {
    @extend %title;
    font-family: f(inputs);
    font-style: italic;
}

%title-img {
  @extend %title;
  color: c(beige);
}

%title-bill {
    @extend %title;
    size: 13px;
    color: c(brown);
}*/
.application__description, .application__important, .label-menu {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-text-transform: uppercase;
}

.profile__menu-items, .menu-items {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-color: #000;
  font-text-transform: uppercase;
}

@media only screen and (max-width: 480px) {
  .profile__menu-items, .menu-items {
    size: 14px;
  }
}

.nav-sublist-dropdown {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-text-transform: uppercase;
}

.sbtt-slider {
  font-family: "RobotoSlab", serif;
  font-size: 16px;
}

.ttl-gridImg {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  text-transform: uppercase;
}

.sbttl-gridImg {
  font-size: 14px;
  text-transform: uppercase;
}

.sbttl-gridImgBuy {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}

.ttl-banner {
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  font-weight: 900;
  text-transform: uppercase;
  color: #ff0000;
  line-height: 25px;
}

.sbttl-banner {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.ttl-newsletter {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.ttl-banner-inner {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-text-transform: uppercase;
  font-weight: 600;
}

.ttl-footer-contact {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.ttl-footer-mail {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.ttl-footer-sections {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.ttl-footer-items {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300;
}

.preview-modal__info-title, .ttl-footer-post {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 24px;
}

.ttl-product {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-text-transform: uppercase;
  font-line-height: 20px;
}

.search__sbttl, .sbttl-product-price {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-text-transform: uppercase;
  font-weight: bold;
}

.application__ttl, .preview-modal__title, .address__title, .billings__title, .ttl-show-product {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-text-transform: uppercase;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 39px;
}

.application__panel--counter, .sbttl-show-product {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-line-height: 23px;
}

.sbttl-show-product-price {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-line-height: 30px;
}

.ttl-show-product-content {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-line-height: 19px;
}

.show-content-ttl-product {
  font-size: 13px;
  font-line-height: 19px;
}

.ttl-preview {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-line-height: 23px;
  font-weight: 600;
}

.thankyou-page__section-title, .thankyou-page__section-label, .ttl--shop-carrito {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ttl--carrito-product {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 100;
  letter-spacing: 1px;
}

.ttl-products-related {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: normal;
}

.application__panel--body label, .application__fonts-label, .preview-modal__info-item, .sbttl-products-related {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.price-products-related {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.faq__title, .sbttl-faq {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 23px;
}

.ttl-blog {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.ttl-blog-commets {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.sbttl-blog-commets {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.thankyou-page__title, .ttl-mycount {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-line-height: 39px;
}

.ttl-modal-mycount-session {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-line-height: 21px;
}

/*%paragraph {
    font-family: f(sans);
    font-size: 22px;
    font-weight: normal;
    color: c();
    line-height: lh(lg);
}

%paragraph-small {
    @extend %paragraph;
    font-size: 15px;
    font-weight: 200;
    color: c();
    line-height: lh(md);
}

*/
.thankyou-page__address-item, .profile__count, .address__quote, .address__empty, .address__shipping, .paragraph-newsletter {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 23px;
}

.footer-description {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-text-transform: uppercase;
}

.cards__numbers, .footer-price {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.footer-date {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.footer-date-month {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.footer-post-author {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.footer-copyright {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.footer-terms {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.faq__content, .paragraph-faq {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 23px;
}

.thankyou-page__spei, .paragraph-blog {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 23px;
}

.paragraph-blog-date {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 24px;
}

.thankyou-page__spei, .paragraph-blog {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 24px;
}

.paragraph-mycount {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 23px;
}

.btn-slider {
  font-size: 14px;
}

.profile__account-button, .btn-newsletter {
  font-size: 12px;
  text-transform: uppercase;
}

.btn-personalize {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.application__buy--btn, .thankyou-page__invoice-button, .orders__button, .cards__button, .billings__button, .btn-add-car {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

.btn-price-car {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.btn-Car {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  background-color: #169688;
  color: #fff;
  line-height: 40px;
  height: 40px;
  padding: 0 28px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  text-align: center;
}

.btn-Car:hover, .btn-Car:focus {
  opacity: 0.9;
  color: #fff;
}

.btn-delete-car {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  border-color: #000;
  color: #000;
  border-width: 1px;
  margin: 0 auto;
  display: block;
  padding: 8px 10px;
}

.btn-delete-car:hover {
  background-color: #000;
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-access {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

.btn-modal-access {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 15px;
}

/*%input {
    border: transparent;
    border-bottom: 1px solid c();
    margin-bottom: s(3/2);
    padding-left: s();
    padding-bottom: 0;
    font-family: f(sans);
    font-size: 15px;
    font-weight: 200;
    color: c();
    -webkit-appearance: none;
    -moz-appereance: none;
    appereance: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;

    &::-webkit-input-placeholder {
      font-family: f(inputs);
      font-size: 16px;
      font-style: italic;
      color: c();
      text-transform: uppercase;

      @include under(bag) {
        font-size: 14px;
      }

      @include under(sm) {
        font-size: 16px;
      }
    }
  	&:-moz-placeholder {
      font-family: f(inputs);
      font-size: 16px;
      font-style: italic;
      color: c();
      text-transform: uppercase;
    }
  	&::-moz-placeholder {
      font-family: f(inputs);
      font-size: 16px;
      font-style: italic;
      color: c();
      text-transform: uppercase;
    }
  	&:-ms-input-placeholder {
      font-family: f(inputs);
      font-size: 16px;
      font-style: italic;
      color: c();
      text-transform: uppercase;
    }
}

%input-footer {
    // @extend %input-type;
    @include input-styling(c(base), c(light), 0, none, transparent, 0, 7px, 7px, 368px);
    height: 23px;
    font-family: f(sans);
    font-size: 14px;
  	font-weight: 200;
  	text-align: center;
}
*/
.profile__account-input, .input-newsletter {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*%label {
    font-family: f(sans);
    font-size: 12px;
    font-weight: normal;
    color: c();
    text-transform: uppercase;
}

%label-select {
    @extend %label;
    font-family: f(inputs);
    font-size: 16px;
    font-style: italic;
}

%label-footer {
    @extend %title;
    font-size: 13px;
    font-weight: bold;
}

%label-light {
    @extend %label;
    font-size: 11px;
    font-weight: 200;
    text-transform: none;
}*/
.label-newsletter {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.label-text-proof {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-line-height: 19px;
}

.thankyou-page__payment-items, .label-mycount {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-line-height: 14px;
}

.profile__account-label, .profile__account-link, .label-modal-user {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.divisor {
  width: 100%;
  padding-top: 70px;
  border-bottom: 1px solid;
}


.order-divisor {
  width: 100%;
  border-bottom: 1px solid;
}

/*_________________________ Grid _________________________ */
.grid__row {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.grid__container {
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto 60px auto;
  max-width: 1180px;
  width: 100%;
}

.grid__col-1-1, .grid__col-1-2, .grid__col-1-3, .grid__col-1-4, .grid__col-1-5 {
  position: relative;
}

.grid__col-1-1 {
  width: 100%;
}

.grid__col-1-2 {
  width: 50%;
}

.grid__col-1-2:nth-of-type(odd) {
  padding-right: 15px;
}

.grid__col-1-2:nth-of-type(even) {
  padding-left: 15px;
}

@media only screen and (max-width: 768px) {
  .grid__col-1-2 {
    width: 100%;
  }
  .grid__col-1-2:nth-of-type(odd) {
    padding-right: 0;
  }
  .grid__col-1-2:nth-of-type(even) {
    padding-left: 0;
  }
}

.grid__col-1-2--application {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 992px) {
  .grid__col-1-2--application {
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 100%;
  }
  .grid__col-1-2--application:nth-of-type(odd) {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 992px) {
  .grid__col-1-2--application {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.grid__col-1-3 {
  width: 33.3333333333%;
}

.grid__col-1-3:nth-of-type(3n-2) {
  padding-right: 20px;
}

.grid__col-1-3:nth-of-type(3n-1) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-3:nth-of-type(3n) {
  padding-left: 20px;
}

@media only screen and (max-width: 768px) {
  .grid__col-1-3 {
    width: 100%;
  }
  .grid__col-1-3:nth-of-type(3n-2) {
    padding-right: 0;
  }
  .grid__col-1-3:nth-of-type(3n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-3:nth-of-type(3n) {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .grid__col-1-3--products:nth-of-type(3n-2) {
    padding-right: 0;
  }
  .grid__col-1-3--products:nth-of-type(3n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-3--products:nth-of-type(3n) {
    padding-left: 0;
  }
  .grid__col-1-3--products {
    width: 50%;
  }
  .grid__col-1-3--products:nth-of-type(odd) {
    padding-right: 15px;
  }
  .grid__col-1-3--products:nth-of-type(even) {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .grid__col-1-3--products {
    width: 100%;
  }
  .grid__col-1-3--products:nth-of-type(odd) {
    padding-right: 0;
  }
  .grid__col-1-3--products:nth-of-type(even) {
    padding-left: 0;
  }
}

.grid__col-1-4 {
  width: 25%;
}

.grid__col-1-4:nth-of-type(4n-3) {
  padding-right: 20px;
}

.grid__col-1-4:nth-of-type(4n-2) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-4:nth-of-type(4n-1) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-4:nth-of-type(4n) {
  padding-left: 20px;
}

@media only screen and (max-width: 992px) {
  .grid__col-1-4:nth-of-type(4n-3) {
    padding-right: 0;
  }
  .grid__col-1-4:nth-of-type(4n-2) {
    padding-left: 0;
    padding-right: 0;
  }
  .grid__col-1-4:nth-of-type(4n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-4:nth-of-type(4n) {
    padding-left: 0;
  }
  .grid__col-1-4 {
    width: 50%;
  }
  .grid__col-1-4:nth-of-type(odd) {
    padding-right: 15px;
  }
  .grid__col-1-4:nth-of-type(even) {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .grid__col-1-4 {
    width: 100%;
  }
  .grid__col-1-4:nth-of-type(odd) {
    padding-right: 0;
  }
  .grid__col-1-4:nth-of-type(even) {
    padding-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .grid__col-1-4--footer {
    width: 100%;
  }
  .grid__col-1-4--footer:nth-of-type(odd) {
    padding-right: 0;
  }
  .grid__col-1-4--footer:nth-of-type(even) {
    padding-left: 0;
  }
}

.grid__col-1-5 {
  width: calc(100% / 5);
}

.grid__col-1-5 {
  width: 20%;
}

.grid__col-1-5:nth-of-type(5n-4) {
  padding-right: 20px;
}

.grid__col-1-5:nth-of-type(5n-3) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-5:nth-of-type(5n-2) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-5:nth-of-type(5n-1) {
  padding-right: 10px;
  padding-left: 10px;
}

.grid__col-1-5:nth-of-type(5n) {
  padding-left: 20px;
}

@media only screen and (max-width: 992px) {
  .grid__col-1-5 {
    width: calc(100% / 4);
  }
  .grid__col-1-5:nth-of-type(5n-4) {
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(5n-3) {
    padding-left: 0;
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(5n-2) {
    padding-left: 0;
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(5n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-5:nth-of-type(5n) {
    padding-left: 0;
  }
  .grid__col-1-5 {
    width: 25%;
  }
  .grid__col-1-5:nth-of-type(4n-3) {
    padding-right: 20px;
  }
  .grid__col-1-5:nth-of-type(4n-2) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .grid__col-1-5:nth-of-type(4n-1) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .grid__col-1-5:nth-of-type(4n) {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .grid__col-1-5 {
    width: calc(100% / 3);
  }
  .grid__col-1-5:nth-of-type(4n-3) {
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(4n-2) {
    padding-left: 0;
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(4n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-5:nth-of-type(4n) {
    padding-left: 0;
  }
  .grid__col-1-5 {
    width: 33.3333333333%;
  }
  .grid__col-1-5:nth-of-type(3n-2) {
    padding-right: 20px;
  }
  .grid__col-1-5:nth-of-type(3n-1) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .grid__col-1-5:nth-of-type(3n) {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .grid__col-1-5 {
    width: calc(100% / 2);
  }
  .grid__col-1-5:nth-of-type(3n-2) {
    padding-right: 0;
  }
  .grid__col-1-5:nth-of-type(3n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .grid__col-1-5:nth-of-type(3n) {
    padding-left: 0;
  }
  .grid__col-1-5 {
    width: 50%;
  }
  .grid__col-1-5:nth-of-type(odd) {
    padding-right: 15px;
  }
  .grid__col-1-5:nth-of-type(even) {
    padding-left: 15px;
  }
}

.grid__box {
  position: relative;
  width: 100%;
}

/*Grid Debug*/
.debug .grid__row {
  position: relative;
  min-height: 200px;
}

.debug .grid__container {
  position: relative;
  min-height: 100px;
  margin-bottom: 20px;
}

.debug .grid__col-1-2, .debug .grid__col-1-3, .debug .grid__col-1-4 {
  position: relative;
  margin-bottom: 10px;
  display: flex;
}

.debug .grid__col-1-2:nth-of-type(even), .debug .grid__col-1-3:nth-of-type(even), .debug .grid__col-1-4:nth-of-type(even) {
  min-height: 100px;
}

.debug .grid__col-1-2:nth-of-type(odd), .debug .grid__col-1-3:nth-of-type(odd), .debug .grid__col-1-4:nth-of-type(odd) {
  min-height: 100px;
}

.debug .grid__box {
  position: relative;
  background-color: rgba(7, 7, 7, 0.7);
  min-height: 50px;
}

.header {
  /* primera parte de menú */
  /* seguda parte de menú */
}

.header__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .header__navbar {
    display: none;
  }
}

.header__navbar--elements {
  width: calc(100%/2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .elements {
  position: relative;
}

@media only screen and (max-width: 992px) {
  .header .elements {
    top: 5px;
    right: 15px;
  }
}

.header .elements__login {
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 0px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.header .elements__login > a:hover {
  color: #169688;
  text-decoration: underline;
  cursor: pointer;
}

.header .elements__login > form input {
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  padding: 0px;
  height: auto;
}

.header .elements__login > form input:hover {
  color: #169688;
  text-decoration: underline;
  cursor: pointer;
}

.header .elements__login-anchor:hover {
  color: #169688;
  text-decoration: underline;
}

.header .elements__login:hover .elements__login--tooltip {
  visibility: visible;
}

.header .elements__login--tooltip {
  width: 300px;
  background-color: #FFFFFF;
  border: solid 3px #E4E4E4;
  visibility: hidden;
  position: absolute;
  z-index: 999999;
  padding: 20px;
  top: 30px;
  right: 0px;
  transition-duration: 1s;
}

@media only screen and (max-width: 992px) {
  .header .elements__login--tooltip {
    top: 16px;
  }
}

.header .elements__login--tooltip::after {
  content: "";
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-color: lightgray transparent transparent transparent;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: -15px;
  left: 150px;
}

.header .elements__login--ttl {
  display: flex;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
  padding-bottom: 10px;
}

.header .elements__login--ttl p {
  width: 130px;
}

.header .elements__login--ttl--mb {
  margin-bottom: 10px;
}

.header .elements__login--form label {
  font-size: 14px;
  color: #767676;
  text-transform: lowercase;
  line-height: 22px;
}

.header .elements__login--form input {
  margin: 10px 0px;
}

.header .elements__login--line {
  width: 50%;
  border-bottom: solid 1px #000;
  align-self: center;
}

.header .elements__login--btn-acceder {
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: 1px;
  margin: 10px 0px;
  padding: 10px 22px;
  background-color: #169688;
  border: transparent;
  width: 100%;
}

.header .elements__login--btn-acceder:hover {
  cursor: pointer;
}

.header .elements__login--btn-registrar {
  background-color: #FFFFFF;
  border: solid 2px #000;
  color: #000;
  text-transform: uppercase;
  padding: 10px 22px;
}

.header .elements__login--btn-registrar:hover {
  background-color: #000;
  color: #FFFFFF;
  text-decoration: underline;
}

.header .elements__search {
  width: 33px;
  height: 33px;
  font-size: 12px;
  background-color: #E4E4E4;
  display: flex;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  line-height: 10px;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}

.header .elements__search:hover .elements__search--tooltip {
  visibility: visible;
}

.header .elements__search--tooltip {
  display: flex;
  max-width: 350px;
  background-color: #FFFFFF;
  border: solid 3px #E4E4E4;
  visibility: hidden;
  position: absolute;
  z-index: 999999;
  padding: 20px;
  top: 30px;
  right: 0px;
  transition-duration: 1s;
}

.header .elements__search--tooltip::after {
  content: "";
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-color: lightgray transparent transparent transparent;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: -15px;
  right: 10px;
}

.header .elements__search--tooltip input {
  width: 150px;
}

.header .elements__search--tooltip button {
  background-color: #D2D2D2;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  line-height: 15px;
  margin-left: 30px;
  padding: 12px 40px;
  width: 132px;
}

.header .elements__buy {
  padding: 10px 15px;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  position: relative;
}

@media only screen and (min-width: 992px) {
  .header .elements__buy {
    border: 1px dashed #D2D2D2;
  }
}

@media only screen and (max-width: 992px) {
  .header .elements__buy {
    padding: 0;
  }
}

.header .elements__buy i {
  position: absolute;
  top: -18px;
  right: 15px;
  font-size: 22px;
  cursor: pointer;
  color: #169688;
}

@media only screen and (max-width: 992px) {
  .header .elements__buy .total-price {
    display: none;
  }
}

.header .elements__buy--circle {
  width: 20px;
  height: 20px;
  background-color: #D2D2D2;
  font-size: 13px;
  padding: 11px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  position: absolute;
  top: -35px;
  right: 0px;
}

@media only screen and (max-width: 992px) {
  .header .elements__buy--circle {
    top: -25px;
    right: -3px;
  }
}

.header .elements__buy:hover .elements__buy--tooltip {
  visibility: visible;
}

.header .elements__buy--tooltip {
  width: 300px;
  background-color: #FFFFFF;
  border: solid 3px #E4E4E4;
  position: absolute;
  z-index: 999999;
  padding: 20px;
  top: 50px;
  right: 0px;
  transition-duration: 1s;
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 992px) {
  .header .elements__buy--tooltip {
    top: 17px;
  }
}

.header .elements__buy--tooltip::after {
  content: "";
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-color: lightgray transparent transparent transparent;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: -17px;
  right: 0px;
}

.header .elements__buy--ttl {
  color: #767676;
  font-size: 14px;
  line-height: 25.2px;
  text-align: left;
  font-weight: 400;
  padding: 8px;
  border-bottom: solid 1px #D2D2D2;
}

.header .elements__buy--description {
  display: flex;
  width: 100%;
  padding: 8px;
  flex-wrap: nowrap;
  border-bottom: solid 1px #D2D2D2;
}

.header .elements__buy--txt {
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  padding: 5px;
  text-align: left;
}

.header .elements__buy--txt p:last-of-type {
  font-weight: 600;
}

.header .elements__buy--icon i {
  position: absolute;
  top: 98px;
  color: #D2D2D2;
}

.header .elements__buy--subtotal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 400;
  padding: 15px 8px;
  font-size: 14px;
}

.header .elements__buy--subtotal p:last-of-type {
  font-weight: 600;
}

.header .elements__buy--botones {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  text-align: center;
}

.header .elements__buy--botones a:first-child {
  color: #FFFFFF;
  background-color: #D2D2D2;
  padding: 13px 6px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 400;
}

.header .elements__buy--botones a:first-child:hover {
  background-color: #767676;
}

.header .elements__buy--botones a:last-child {
  color: #FFFFFF;
  background-color: #000;
  padding: 12px 6px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 400;
}

.header .elements__buy--botones a:last-child:hover {
  background-color: #169688;
}

.header .elements__buy--img {
  max-width: 80px;
  width: 100%;
  margin-right: 10px;
  height: auto;
}

.header .elements__buy--img-size {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.header__navbarList {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: solid 1px lightgrey;
  border-bottom: solid 1px lightgrey;
}

@media screen and (max-width: 1160px) {
  .header__navbarList {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .header__navbarList {
    display: none;
  }
}

.header__navbarList--fixed {
  border: none !important;
}

.header__navbarList--active {
  color: #169688 !important;
}

.header__navbarList--arrow {
  color: #b9b9b9;
  font-size: 9px !important;
  font-weight: 100 !important;
  margin-bottom: 0px;
}

.header__navbarList--container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.header__navbarList--menuOption {
  padding: 22px;
  border-left: solid 1px lightgrey;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

@media screen and (max-width: 1160px) {
  .header__navbarList--menuOption {
    padding: 22px 10px;
  }
}

.header__navbarList--menuOption:last-of-type {
  border-right: solid 1px lightgrey;
}

.header__navbarList--menuOptiontext {
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-align: center;
}

.header__navbarList--menuOptiontext:hover {
  color: #169688;
}

.header__navbarList--menuOption:hover .header__navbarList--dropdown--dropdownContainer {
  display: block;
}

.header__navbarList--dropdown {
  position: relative;
  display: inline-block;
  width: 250px;
}

.header__navbarList--dropdown--dropdownContainer {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  overflow: auto;
  z-index: 9;
  border: solid 1px #D2D2D2;
  width: 100%;
  padding: 15px 0px;
  top: 56px;
  left: 0;
}

.header__navbarList--dropdown--dropdownContainerLink {
  color: #767676;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: "RalewayRegular", sans-serif;
  padding: 8px 20px;
  word-break: break-word;
  text-align: left;
}

.header__navbarList--dropdown--dropdownContainerLink:hover {
  color: #169688;
}

.header__navbarList--menuOptionLast {
  padding: 22px;
  border-left: solid 1px lightgrey;
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.header__navbarList .show {
  display: block;
}

.header__fixed {
  background-color: #FFFFFF;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 888888;
  border-bottom: 1px solid #D2D2D2;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  overflow: visible !important;
}

@media only screen and (max-width: 992px) {
  .header__fixed {
    display: none;
  }
}

.header__fixed-logo {
  width: 164px;
}

.header__fixed-cart {
  width: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
}

.header__fixed-items {
  width: calc(100% - 165px - 150px);
}

.header__fixed-container {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px auto;
  background-color: #FFFFFF;
}

.element__buy--img {
  max-width: 80px;
  width: 100%;
  margin-right: 10px;
  height: auto;
}

.element__buy--img-size {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/*termina header y aparte sidenav*/
.footer__logo--img {
  max-width: 350px;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .footer__logo--img {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .footer__logo--img {
    width: 100%;
  }
}

.footer__acerca {
  color: #AAA;
  letter-spacing: 1px;
  margin-top: 5px;
  margin-bottom: 18px;
}

.footer__acerca a:hover {
  color: #000;
}

.footer__contact {
  color: #000;
  letter-spacing: 1px;
}

.footer__contact--mail a {
  color: #767676;
  letter-spacing: 1px;
}

.footer__contact--mail a:hover {
  color: #000;
}

@media only screen and (max-width: 768px) {
  .footer__redes {
    text-align: center;
  }
}

.footer__redes span a i {
  background: #464646;
  color: #FFF;
  width: 25px;
  text-align: center;
  height: 25px;
  border-radius: 20px;
  font-weight: 400;
  margin: 25px 8px;
  font-size: 14px;
  line-height: 24px;
}

.footer__redes span a i:hover {
  background: #E4E4E4;
}

.footer__column--blog--date {
  display: inline-flex;
}

.footer__column--blog--date a p:first-child {
  color: #169686;
  border: 2px solid #169686;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 18px;
  padding: 14px;
  margin: 20px 20px 10px;
}

.footer__column--blog--date a p:nth-child(2) {
  text-align: center;
}

.footer__column--blog--post {
  width: 100%;
  margin-top: 18px;
  cursor: pointer;
}

.footer__column--blog--post:hover {
  color: #169688;
}

.footer__column--blog--post a h4 {
  letter-spacing: .5px;
}

.footer__column--blog--post h6 {
  color: #AAA;
  margin-top: 14px;
}

.footer__column h3 {
  color: #169688;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
}

.footer__column--product {
  margin: 35px 0px;
  border-bottom: 1px solid #DDD;
  padding-bottom: 35px;
}

.footer__column--product--img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer__column--product--img-container {
  height: 72px;
  width: 72px;
  overflow: hidden;
  border: 1px solid #DDD;
  padding: 5px;
  display: inline-block;
}

.footer__column--product--description {
  padding-left: 15px;
  float: right;
  display: inline-block;
  width: calc(100% - 72px);
}

.footer__column--product--description a h4:hover {
  color: #767676;
}

.footer__column--product--description--price {
  color: #169688;
}

.footer__column--faq {
  margin: 5px;
  color: #767676;
}

.footer__column--faq li {
  border-bottom: 1px solid #DDD;
  padding: 20px 3px;
}

.footer__column--faq li:last-child {
  border-bottom: none;
}

.footer__column--faq li a:hover {
  color: #000;
}

.footer__column--faq li a i {
  color: #4286c4;
  margin-right: 10px;
}

.footer__copy {
  width: 100%;
  border-top: 2px solid #DDD;
  padding-top: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #767676;
}

.footer__copy a, .footer__copy i {
  color: #169688;
}

.footer__copy span img {
  width: 30px;
  display: inline;
}

@media only screen and (max-width: 768px) {
  .footer__copy span img {
    width: 100%;
    max-width: 30px;
    margin-right: auto;
    margin-left: auto;
  }
}

.footer__copy--terms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

@media only screen and (max-width: 992px) {
  .footer__copy--terms {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .footer__copy--terms {
    width: 100%;
  }
}

.auth__title {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  line-height: 1.3;
}

.auth__form--titleInput {
  color: #767676;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  margin: 10px 0px;
}

.auth__form--submit {
  background-color: #169688;
  color: #FFFFFF;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  padding: 6px 18px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 3% 0%;
  width: auto;
  height: 27px;
  border: none;
}

.auth__form--input {
  margin: 0px 0px 10px 0px;
}

.auth__remember {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #767676;
}

.auth__text {
  font-family: "Roboto", sans-serif;
  color: #767676;
  font-size: 13px;
  line-height: 23.4px;
  margin-bottom: 20px;
}

.auth__restore-btn {
  height: 40px;
  width: 100%;
  max-width: 258px;
  padding: 10px 20px 10px;
  margin: 22px 0px !important;
}

.auth__links:hover {
  color: #169688;
}

.auth__links--password {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 13px;
  line-height: 70px;
}

.auth__links--password:hover {
  color: #169688;
}

.auth__register--text {
  color: #767676;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 23.4px;
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  margin-left: 30px;
}

.google-button:focus, .google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}

.google-button:active {
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 10ms;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto',arial,sans-serif;
}

.header-mobile__container {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  border-bottom: solid 2px #E4E4E4;
}

@media only screen and (min-width: 992px) {
  .header-mobile__container {
    display: none;
  }
}

.header-mobile__burger {
  width: calc(100%/3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: 15px;
  cursor: pointer;
}

.header-mobile__burger i {
  font-size: 20px;
  color: #767676;
}

.header-mobile__image {
  width: calc(100%/3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-mobile__image img {
  width: 170px;
  height: auto;
}

.header-mobile__buy {
  width: calc(100%/3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 15px;
}

.header-mobile__buy--price {
  width: 20px;
  height: 20px;
  font-size: 13px;
  background-color: #E4E4E4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  line-height: 10px;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 5px;
  right: 15px;
}

@media only screen and (max-width: 480px) {
  .header-mobile__buy--price {
    display: none;
  }
}

.header-mobile__buy--bag {
  font-size: 20px;
  color: #169688;
  cursor: pointer;
  position: absolute;
  right: 35px;
}

@media only screen and (max-width: 480px) {
  .header-mobile__buy--bag {
    right: 5px;
  }
}

.header-mobile__buy--search {
  width: 25px;
  height: 25px;
  font-size: 13px;
  background-color: #C1C1C1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  right: 70px;
}

.header-mobile__buy--search:hover .elements__search--tooltip {
  visibility: visible;
}

@media only screen and (max-width: 480px) {
  .header-mobile__buy--search {
    right: 35px;
  }
}

.header-mobile__buy--search i {
  color: #FFFFFF;
  font-size: 12px;
}

.navside {
  width: 250px;
  background-color: #1a1a1a;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 100%;
  overflow-x: hidden;
}

.navside__container {
  width: 100%;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  background-color: #333;
  flex-direction: column;
  align-items: center;
}

.navside__title {
  width: 100%;
  padding-left: 17px;
  font-size: 18px;
  font-weight: 700;
}

.navside__title-bars {
  font-size: 16px;
}

.navside__title-container {
  background-color: #1e1e1e;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 15px 20px 30px;
}

.navside__title-container:hover {
  cursor: pointer;
}

.navside__title-container:hover .navside__close {
  background-position: bottom center;
}

.navside__close {
  color: #E4E4E4;
  background-image: url(/images/small-close.png);
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  width: 16px;
  height: 14px;
}

.navside__options {
  flex-direction: column;
  position: absolute;
  top: 75px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navside__menu--item {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  position: relative;
}

.navside__menu--item-head {
  padding: 15px 20px 15px 25px;
  width: 100%;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navside__menu--item-link:hover, .navside__menu--item-arrow:hover {
  color: #169688;
  cursor: pointer;
}

.navside__menu--item-arrow {
  font-size: 12px !important;
  font-weight: lighter !important;
}

.navside__menu--item-active {
  color: #169688;
}

.navside__menu--item-list {
  background-color: #2c2c2c;
  padding: 15px 5px 15px 35px;
  margin: 15px 0px;
  display: none;
}

.navside__menu--item-list::before {
  content: '';
  display: block;
  left: 30px;
  top: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #2c2c2c;
  border-width: 10px 10px;
}

.navside__menu--item-subitem {
  color: #767676;
  font-size: 12px;
  padding: 13px 0;
}

.navside__menu--item-subitem:hover, .navside__menu--item-subitem-active {
  color: #169688;
  cursor: pointer;
}

.navside__user {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 30px 0px 30px;
}

.navside__user--account {
  border-bottom: 1px solid #262626;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  padding: 15px 30px 15px 0px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.navside__user--account-icon {
  font-size: 16px !important;
  margin-right: 10px;
}

.navside__user--login {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 15px 0 15px 0px;
}

.navside__user--login:hover {
  color: #169688;
  cursor: pointer;
}

.navside__user--logout {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 0px;
  height: auto;
  background-color: transparent;
  border: none;
  width: auto;
}

.navside__user--logout:hover {
  color: #169688;
  cursor: pointer;
}

.alert {
  border: 1px solid transparent;
  font-size: 13px;
  color: #767676;
  padding: 15px;
  width: 100%;
  position: relative;
  padding-left: 60px;
  line-height: 24px;
  margin-top: 20px !important;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.alert:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -11px;
  line-height: 1;
  font-size: 24px;
  font-family: 'Cabin';
}

.alert__success, .alert__danger {
  width: 100%;
  position: relative;
  display: none;
}

.alert__success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert__success:before {
  content: "\f00c";
}

.alert__danger {
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert__danger:before {
  content: "\f00d";
}

.alert__container {
  position: relative;
  background-color: #FFFFFF;
  width: 100%;
  transition: all 0.3s ease;
}

.alert__close {
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  color: black;
  cursor: pointer;
  opacity: 0.4;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
}

.alert__close:hover {
  background: rgba(0, 0, 0, 0.2);
}

.alert__text {
  font-size: 14px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin: 20px auto;
}

.swiper-slide-container {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 100%;
  max-width: 600px;
  margin: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.gallery-top {
  height: 80%;
  width: 100%;
}

.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

.ml {
  margin-left: 10px;
}

.mr {
  margin-right: 10px;
}

.scroll--top {
  right: 25px;
  bottom: 50px;
  color: #FFFFFF;
  cursor: pointer;
  z-index: 1000;
  display: inline-block;
  text-align: center;
  position: fixed;
  font-size: 30px !important;
  font-weight: bold;
  background-color: #565656;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  padding-top: 5px;
  transition: all 0.25s ease;
}

.scroll--top:hover {
  background-color: #000;
}

.opacity {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
  left: 0;
  display: none;
  transition: transform 0.3s ease-in-out;
}

.main-wrap {
  flex: 1 0 auto;
}

.no-margin {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.checkout__radiobuttons, .billings__checkboxes {
  border-radius: 3px !important;
}

.gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

.gallery__container {
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.gallery__container--img {
  object-fit: cover;
  object-position: center;
  height: 208px;
}

.gallery__text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 40px;
  display: block;
}

.gallery__text .ttl-product {
  margin: 15px 0px;
  text-transform: uppercase;
}

.gallery__text .ttl-product:hover {
  color: #169688;
}

.gallery__text .sbttl-product-price {
  margin: 0 0 20px;
}

.gallery__text .btn-personalize {
  line-height: 15px;
  font-size: 11px;
  padding: 8px 20px;
  border: 2px solid #000;
  display: inline-block;
}

.gallery__text .btn-personalize:hover {
  background-color: #000;
  color: #fff;
  font-weight: 500;
}

.swiper-button-next, .swiper-button-prev {
  height: 24px !important;
  margin-top: -12px !important;
  background-size: inherit !important;
}

/*

table {
  width: 100%;
  border-collapse: collapse;
}
tr:nth-of-type(odd) {
  //background: #eee;
}
th {
  //background: #333;
	font: 15px/15px 'Raleway', Arial, Helvetica, sans-serif;
	font-weight: 500;
	letter-spacing: 1px;
	color: #000;
	text-align: center;
	text-transform: uppercase;
}
td, th {
  padding: 6px;
  border: 1px solid #ddd;
  //text-align: left;
}
.products__table{
	vertical-align: middle;

	font: 14px/22px 'Raleway', sans-serif;

	.name{
		display: table-cell;
    	vertical-align: middle;
    	text-align: left;
    	font-weight: 300;
	}
	.price, .total{
		family: f(ralewaRegular);
		font-size:20px;
		font-weight: 700;
		text-align: center;
		font-weight: bold;
	}
}




@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table, thead, tbody, th, td, tr {
		display: block;
	}

	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr { border: 1px solid #ddd; }

	td {
		border: none;
		border-bottom: 1px solid #ddd;
		position: relative;
		padding-left: 50%;
	}

	td:before {
		position: absolute;

		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}





	td:nth-of-type(1):before { content: ""; }
	td:nth-of-type(2):before { content: ""; }
	td:nth-of-type(3):before { content: "Producto"; }
	td:nth-of-type(4):before { content: "Precio"; }
	td:nth-of-type(5):before { content: "Total"; }
}*/
.carrito__img {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  object-fit: contain;
  height: 250px;
}

.carrito__vacio {
  margin-bottom: 50px;
}

.carrito__vacio--ttl {
  font-family: 'ralewayRegular';
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  line-height: 1.3;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .carrito__vacio--ttl {
    margin-top: 30px;
  }
}

.carrito__vacio--sbttl {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-bottom: 20px;
  color: #767676;
  line-height: 1.8;
  text-align: center;
}

.carrito__vacio .btn-Car {
  width: 215px;
  display: block;
  margin: 0 auto;
}

.carrito__Table {
  max-width: calc(100% - 25px);
  margin: 0 auto;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .carrito__Table {
    max-width: calc(100% - 15px);
  }
}

.carrito__Table--img {
  width: 45%;
  padding: 25px 16px;
}

.carrito__Table table {
  width: 100%;
}

.carrito__Table tr.ttl--shop-carrito th {
  padding: 10px;
}

.carrito__Table td, .carrito__Table th {
  padding: 25px 25px;
  border-width: 1px 0px 1px 0px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.carrito__Table td {
  vertical-align: middle;
}

.carrito__Table td:last-child {
  font-size: 0.95em;
  line-height: 1.4;
  text-align: left;
}

.carrito__Table tr:nth-child(2n) {
  background-color: white;
}

.carrito__desc--col-1-2 {
  width: 50%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.carrito__desc--col-1-2 h3 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  text-decoration: none;
  margin-top: 40px;
}

.carrito__desc--col-1-2 .btn-Car {
  float: right;
}

.carrito__desc--checkout_coupon {
  margin-top: 20px;
}

.carrito__desc--checkout_coupon .cupon {
  width: 60%;
}

.carrito__desc--checkout_coupon .btn-Car {
  width: 38% !important;
  margin-top: 20px;
}

.carrito__desc--tableTotal {
  width: 100%;
  margin: 0 0 20px;
}

.carrito__desc--tableTotal th, .carrito__desc--tableTotal td {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: bottom;
  padding: 20px 0px;
  text-align: left;
}

.carrito__desc--tableTotal tr {
  border-bottom: 1px solid #000;
}

.carrito__link {
  cursor: pointer;
  text-align: center;
  height: 28px;
  padding-top: 10px;
  font-size: 12px;
  text-decoration: underline;
  font-weight: normal;
}

.carrito__quantity-td {
  min-height: 122px;
  position: relative;
  top: 14px;
}

.carrito__preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 45px;
}

.carrito__preview-modal-main-container {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  padding: 0;
  max-height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
  border-radius: 2px;
  padding: 10px 20px;
  will-change: top, opacity;
}

.carrito__title {
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .carrito__title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .carrito__Table table, .carrito__Table tr, .carrito__Table td {
    display: block;
  }
  .carrito__Table tr.ttl--carrito-product {
    border-width: 1px 0px 1px 0px;
    border: 1px solid #ddd;
  }
  .carrito__Table td, .carrito__Table th {
    padding: 15px 15px;
    border-width: 1px 0px 1px 0px;
    border: 0px solid #ddd;
  }
  .carrito__Table tr:first-child {
    display: none;
  }
  .carrito__Table td:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
  }
  .carrito__Table td.btn-delete-car {
    margin: 0 auto 0 0;
  }
  .carrito__Table tr {
    padding: 10px 0;
    position: relative;
  }
  .carrito__Table td:not(:first-child) {
    clear: both;
    margin-left: 150px;
    padding: 4px 20px 4px 20px;
    position: relative;
    text-align: left;
  }
  .carrito__Table td:not(:first-child):before {
    color: #bbb;
    content: '';
    display: block;
    left: 0;
    position: absolute;
  }
  .carrito__Table .btn-delete-car {
    margin: 0px;
  }
  .carrito__desc--col-1-2 {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .carrito__desc--checkout_coupon .btn-Car {
    padding: 0px;
  }
}

.home__categories--container {
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
}

.home__categories--container--link {
  color: #FF6601;
  padding: 0 0 0 4%;
}

.home__categories--container--link:hover {
  text-decoration: underline;
  color: #169688;
}

.home__categories h4 {
  padding: 25% 4% 2%;
  width: 100%;
  word-wrap: break-word;
}

.home__newsletter--ttl {
  margin-bottom: 10px;
}

.home__newsletter--paragraph, .home__newsletter--form--label {
  color: #767676;
}

.home__newsletter--input--mail {
  margin-top: 15px;
}

.home__newsletter--input--submit {
  background: #169688;
  width: auto;
  float: right;
  margin-top: 10px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: normal;
  cursor: pointer;
}

.home__banner img {
  width: 100%;
  height: 100%;
}

.home__banner--ttl {
  line-height: 25px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
  .home__banner--ttl {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .home__banner--ttl {
    font-size: 2em;
  }
}

.home__banner--sbttl {
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 7%;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #FFF;
  letter-spacing: 4px;
}

@media only screen and (max-width: 600px) {
  .home__banner--sbttl {
    letter-spacing: 0px;
    color: #000;
    max-width: 275px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 480px) {
  .home__banner--sbttl {
    display: none;
  }
}

.home__banner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.home__post--blog--bg {
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .home__post--blog--bg {
    margin-bottom: 35px;
  }
}

.home__post--blog--title {
  color: #FFF;
  padding: 3% 0 0 3%;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  max-width: 210px;
  margin-right: auto;
}

.home__slider__container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
  height: 100%;
}

.home__slider__container:hover .home__button {
  visibility: visible;
  opacity: 1;
}

.home__slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.home__slider-text-container {
  left: 25%;
  right: 25%;
  top: 125px;
  position: absolute;
  z-index: 5;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .home__slider-text-container {
    top: 10%;
  }
}

.home__slider-title {
  font-family: "BenchNine", sans-serif;
  color: #FFFFFF;
  font-size: 88px;
  font-weight: 400;
  width: 100%;
  max-width: 492px;
  margin-right: auto;
  margin-left: auto;
  text-transform: uppercase;
  min-height: 135px;
  max-height: 135px;
  line-height: 68px;
  overflow: auto;
}

@media only screen and (max-width: 992px) {
  .home__slider-title {
    font-size: 45px;
    min-height: 100px;
    max-height: 100px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .home__slider-title {
    min-height: 66px;
    max-height: 66px;
    font-size: 44px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 600px) {
  .home__slider-title {
    font-size: 35px;
    line-height: 27px;
    min-height: 52px;
    max-height: 52px;
  }
}

@media only screen and (max-width: 480px) {
  .home__slider-title {
    font-size: 22px;
    line-height: 17px;
    min-height: 35px;
    max-height: 35px;
  }
}

.home__slider-subtitle {
  color: #e1ed00;
  font-family: "RobotoSlab", serif;
  font-size: 19px;
  text-align: center;
  width: 100%;
  max-width: 302px;
  font-weight: 400;
  line-height: 19px;
  margin: 20px auto;
  min-height: 96px;
  max-height: 96px;
  overflow: auto;
}

@media only screen and (max-width: 992px) {
  .home__slider-subtitle {
    margin: 10px auto;
    min-height: 60px;
    max-height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .home__slider-subtitle {
    min-height: 43px;
    max-height: 43px;
    font-size: 23px;
    line-height: 20px;
    margin: 15px auto;
  }
}

@media only screen and (max-width: 600px) {
  .home__slider-subtitle {
    min-height: 34px;
    max-height: 34px;
    font-size: 20px;
    line-height: 17px;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 480px) {
  .home__slider-subtitle {
    font-size: 17px;
    line-height: 15px;
    min-height: 27px;
    max-height: 27px;
    margin: 7px auto;
  }
}

.home__slider-button {
  width: auto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  background-color: rgba(255, 29, 0, 0.75);
  padding: 10px 30px;
  border-color: black;
  box-sizing: border-box;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 30px;
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .home__slider-button {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .home__slider-button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .home__slider-button {
    font-size: 12px;
    line-height: 12px;
    margin-top: 0;
    padding: 6px 20px;
  }
}

.home__button {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 45px !important;
  height: 45px !important;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #FFFFFF;
  text-align: center;
  background-image: none !important;
}

@media only screen and (max-width: 480px) {
  .home__button {
    width: 30px !important;
    height: 30px !important;
  }
}

.home__button:before {
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  color: #FFFFFF;
  line-height: 41px;
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .home__button:before {
    font-size: 15px;
    line-height: 26px;
  }
}

.home__button:hover {
  background-color: #FFFFFF;
}

.home__button:hover:before {
  color: #000;
}

.home__button--next:before {
  content: "\f105";
  right: -1px;
}

.home__button--prev:before {
  content: "\f104";
  left: -1px;
}

.single__gallery-container {
  width: 100%;
  max-width: 465px;
  margin: 0 auto;
  position: fixed;
  top: 150px;
  left: auto;
  right: auto;
}

.single__gallery-ttlResponsive {
  display: none;
}

@media only screen and (max-width: 992px) {
  .single__gallery-ttlResponsive {
    display: block;
  }
}

.checkout__collapse {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border: 1px solid transparent;
  color: #767676;
  padding: 15px;
  width: 100%;
  position: relative;
  padding-left: 60px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.checkout__collapse:first-child {
  margin-bottom: 20px;
}

.checkout__collapse--acceder {
  color: #169688;
}

.checkout__collapse:before {
  content: "\f129";
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -11px;
  line-height: 1;
  font-size: 24px;
  font-family: FontAwesome;
}

.checkout__inputs {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: initial;
  padding: 10px;
  height: 39px;
  font-weight: lighter;
  font-size: 14px;
  margin: 5px 0px 15px 0px;
}

@media only screen and (max-width: 480px) {
  .checkout__inputs {
    font-size: 13px;
  }
}

.checkout__inputs--single {
  margin: 25px 0px 40px 0px;
}

.checkout__select {
  border-radius: 7px;
  margin: 5px 0px 15px 0px;
}

.checkout__datos {
  font-family: "Roboto", sans-serif;
  width: 60%;
  padding: 20px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .checkout__datos {
    width: 100%;
    margin-bottom: 0px;
  }
}

.checkout__datos--ttl {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkout__two-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .checkout__two-inputs {
    flex-wrap: wrap;
  }
}

.checkout__two-inputs--container {
  width: calc(100%/2);
}

@media only screen and (max-width: 480px) {
  .checkout__two-inputs--container {
    width: 100%;
  }
}

.checkout__two-inputs--container:first-of-type {
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  .checkout__two-inputs--container:first-of-type {
    margin-right: 15px;
  }
}

.checkout__discount {
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
}

.checkout__discount-submit {
  width: auto;
  margin-top: 20px;
  background-color: #169688;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  padding: 0px 28px;
  text-align: center;
  text-transform: uppercase;
}

.checkout__labels {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
  color: #767676;
}

@media only screen and (max-width: 480px) {
  .checkout__labels {
    font-size: 13px;
  }
}

.checkout__pedido {
  family: "Roboto", sans-serif;
  width: 40%;
}

.checkout__required {
  color: red;
  font-size: 14px;
  line-height: 22px;
}

.checkout__new-card-container {
  margin: 20px 0px;
}

.checkout__card-label {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .checkout__card-label {
    margin-bottom: 10px;
  }
}

.checkout__text {
  color: #767676;
  line-height: 23.4px;
  margin-bottom: 20px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}

.checkout__text--no-mb {
  margin-bottom: 0;
}

.checkout__text--no-hover:hover {
  color: #767676 !important;
}

.checkout__text--no-hover:last-of-type:hover {
  color: #767676 !important;
}

.checkout__text--other-color:last-of-type {
  color: #767676 !important;
}

.checkout__text:last-of-type {
  color: #000;
}

.checkout__text:last-of-type:hover {
  color: #169688;
}

.checkout__address {
  color: #767676;
  line-height: 23.4px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.checkout__address-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
}

.checkout__address-container:last-of-type {
  margin-bottom: 20px;
}

.checkout__address-container--baseline {
  align-items: baseline;
}

.checkout__address--width {
  width: calc(100% - 26px);
}

.checkout__checkbox {
  display: flex;
  flex-direction: row;
  margin: 15px 0px 13px 0px;
}

.checkout__checkbox input {
  margin-right: 10px;
}

.checkout__checkbox p {
  font-size: 13px;
}

.checkout__textarea {
  height: 120px;
  line-height: 19.5px;
  padding: 9px 6px;
  margin: 5px 0px;
}

.checkout__pedido {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  border: 5px solid #f5f5f5;
  margin: 48px 0px 60px 0px;
}

@media only screen and (max-width: 768px) {
  .checkout__pedido {
    width: 100%;
    padding: 10px !important;
    margin-top: 0px;
  }
}

.checkout__pedido--ttl {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkout__pedido--card {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.checkout__pedido--card span {
  margin-bottom: 0px !important;
  margin-left: 10px;
}

.checkout__pedido--card img {
  width: 140px;
  margin-left: 10px;
}

.checkout__pedido--trans {
  color: #767676;
  line-height: 23.4px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  padding: 15px 0px 24px;
  border-bottom: solid 1px #AAA;
  border-top: solid 1px #AAA;
}

.checkout__pedido--trans input {
  border-radius: 3px;
}

.checkout__pedido--paypalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.checkout__pedido--imgCOntainer {
  max-width: 140px;
}

.checkout__pedido--queEs {
  font-size: 13px;
  color: #252525;
  text-decoration: none;
}

.checkout__pedido--queEs:hover {
  text-decoration: underline;
  color: #169688;
}

.checkout__pedido--paypal {
  border-top: none;
}

.checkout__pedido--terms {
  margin-top: 28px;
  font-size: 14px;
}

.checkout__pedido--terms span:first-of-type {
  color: #767676;
}

.checkout__pedido--btn {
  background-color: #169688;
  color: #FFFFFF;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  padding: 0px 28px;
  text-align: center;
  margin-top: 27px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.checkout__table {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.checkout__table--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #D2D2D2;
  padding: 23px 10px 30px 10px;
}

.checkout__table--row:last-of-type {
  border-bottom: none;
}

.checkout__table--row span:last-child {
  font-weight: 600;
}

.checkout__table--total {
  font-weight: 600;
}

.checkout__table--pedido {
  width: calc(80% - 5px);
}

.checkout__table--price {
  width: calc(20% - 5px);
  text-align: right;
}

.checkout__selects--date {
  width: auto;
  display: block;
  margin-bottom: 24px;
}

.ttl--txt {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  color: #000;
  line-height: 20px;
  margin-right: 10px;
  width: 22%;
  max-width: 150px;
  text-transform: uppercase;
}

.ttl--txt-cupon {
  width: 35%;
  max-width: 200px;
}

.ttl--line {
  height: 1px;
  background-color: #E4E4E4;
  width: 78%;
  margin: 32px 0px;
}

.ttl--line-cupon {
  width: 65%;
}

.labels__pedidos {
  font-size: 14.3px;
}

.pages__container {
  width: 100%;
  padding: 0px 15px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.pages__content ul, .pages__content ol {
  list-style-type: decimal;
  margin: 15px 0 !important;
  text-align: left !important;
  letter-spacing: 0 !important;
}

.pages__content div, .pages__content p, .pages__content span {
  font-size: 18px;
}

.terms {
  font-family: "Roboto", sans-serif !important;
  color: #767676;
  font-size: 13px;
  line-height: 23.4px;
}

.terms__title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.terms__link {
  cursor: pointer;
  color: #000;
  margin: 42px 0px;
}

.terms__link:hover {
  color: #169688;
}

.terms__content {
  line-height: 18.5714px;
}

.terms__content b, .terms__content strong {
  font-weight: bold;
}

.terms__content i, .terms__content em {
  font-style: italic;
}

.error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.error__number {
  font-size: 200px;
  font-weight: 500;
  line-height: 200px;
  color: #2c2c2c;
  margin: 27px 0px;
}

.error__menssage {
  font-size: 36px;
  font-weight: 600;
  line-height: 46.8px;
  color: #2c2c2c;
  text-transform: uppercase;
}

.error__line {
  width: 50px;
  margin: 20px auto;
  display: block;
  height: 3px;
  background: #e6e6e6;
  border: none;
}

.error__text {
  font-size: 13px;
  color: #767676;
  line-height: 23.4px;
  margin-bottom: 20px;
}

.error__search {
  display: flex;
  flex-direction: row;
}

.error__search input {
  margin: 0px 18px;
  width: 270px;
}

.error__search button {
  text-transform: uppercase;
  background-color: #D2D2D2;
  color: #FFFFFF;
  padding: 9px 28px;
  border: none;
  cursor: pointer;
}

.error__search button:hover {
  background-color: #169688;
}

.error__btn-return {
  text-transform: uppercase;
  background-color: #169688;
  color: #FFFFFF;
  padding: 12px 28px;
  border: none;
  cursor: pointer;
  margin: 15px 0px;
}

.blog {
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 1180px) {
  .blog {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .blog {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .blog {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .blog {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .blog {
    width: 100%;
  }
}

.blog__link {
  color: #169688;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-transform: uppercase;
}

.blog__link:hover {
  color: #2c2c2c;
}

.blog__ttl {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 18px 0px 10px 0px;
  text-transform: uppercase;
}

.blog__subttl {
  color: #767676;
  font-size: 13px;
  font-weight: 700;
  line-height: 23.4px;
}

.blog__text {
  color: #767676;
  font-size: 13px;
  line-height: 23.4px;
}

.blog__comment-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 105px 0px 40px 0px;
}

.blog__comment-title--text {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
  margin-right: 10px;
}

.blog__comment-title--line {
  height: 1px;
  background-color: #E4E4E4;
  width: 965px;
}

.comment__container {
  width: 100%;
  max-width: 1120px;
  border: solid 1px #767676;
  height: auto;
  padding: 41px 21px 34px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.comment__media-container {
  display: flex;
  flex-direction: row;
}

.comment__media {
  width: 100%;
  margin-left: 21px;
  display: flex;
  flex-direction: column;
}

.comment__media--image {
  width: 80px;
  height: 80px;
}

.comment__media--image img {
  width: 100%;
  height: auto;
}

.comment__media--ttl {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.comment__media--ttl:hover {
  color: #767676;
}

.comment__media--date {
  margin: 22px 0px 7px;
  font-size: 12px;
  color: #767676;
}

.comment__media--reply {
  color: #169688;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin: 15px 0px;
}

@media only screen and (min-width: 480px) {
  .comment__media--reply {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
  }
}

.comment__media--reply:hover {
  color: #767676;
  text-decoration: underline;
}

.comment__media--line {
  width: 70px;
  height: 3px;
  margin-top: 17px;
  background-color: #E4E4E4;
}

.comment__text {
  color: #767676;
  font-size: 13px;
  line-height: 23.4px;
  text-indent: 92px;
}

@media only screen and (max-width: 768px) {
  .comment__text {
    text-indent: 0px;
  }
}

.faq__title {
  width: 100%;
  color: #767676;
}

.faq__content {
  width: 100%;
  margin-bottom: 20px;
  color: #767676;
}

.faq__content b, .faq__content strong {
  font-weight: bold;
}

.faq__content i, .faq__content em {
  font-style: italic;
}

.application__container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: auto;
}

.application__col-1-2--sticker {
  width: 50%;
}

.application__col-1-2--sticker:nth-of-type(odd) {
  padding-right: 10px;
}

.application__col-1-2--sticker:nth-of-type(even) {
  padding-left: 10px;
}

.application__main-img {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 992px) {
  .application__main-img {
    max-width: 450px;
  }
}

.application__slide {
  text-align: center;
  font-size: 18px;
  background: #FFFFFF;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.application__slide.selected {
  border: solid 1px !important;
}

.application__slide.swiper-slide.swiper-slide-active {
  border: none;
}

.application__gallery {
  padding: 15px 35px;
  position: relative;
}

.application__gallery-button {
  width: 31px;
  position: absolute;
  height: 31px;
  border: 1px solid #eee;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  top: 40%;
  bottom: 40%;
  z-index: 9;
  text-align: center;
  line-height: 26px;
}

.application__gallery-button:before {
  position: relative;
  font-size: 20px;
  font-family: FontAwesome;
  color: #252525;
  top: 0px;
  transition: all 0.2s ease-in-out;
}

.application__gallery-button:hover {
  background-color: #252525;
  border-color: #252525;
  cursor: pointer;
}

.application__gallery-button:hover:before {
  color: #FFFFFF;
}

.application__gallery-button--next {
  right: 0px;
}

.application__gallery-button--next:before {
  content: "\f105";
}

.application__gallery-button--prev {
  left: 0px;
}

.application__gallery-button--prev:before {
  content: "\f104";
}

.application--background-color {
  background-color: #f5f5f5;
  padding-bottom: 30px;
  padding-top: 30px;
}

.application__ttl {
  padding-bottom: 10px;
  width: 100%;
}

.application__description {
  line-height: 15.6px;
}

.application__description--txt {
  padding-bottom: 10px;
}

.application__description--txt:first-of-type {
  font-weight: 700;
}

.application__description--quantity {
  font-weight: 700;
}

.application__important {
  color: #767676;
  line-height: 20px;
}

.application__important--ttl {
  font-weight: 700;
}

.application__important--link {
  color: #000;
}

.application__important--link:hover {
  color: #169688;
}

.application__prices {
  display: flex;
  padding: 32px 0px 43px 0px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.application__prices--real {
  font-size: 22px;
  text-decoration: line-through;
  padding: 0px 5px;
}

.application__prices--final {
  font-size: 30px;
  color: #169688;
  padding: 0px 5px;
}

.application__panel {
  width: 100%;
  height: auto;
  min-height: 170px;
  border: solid 1px #D2D2D2;
  border-radius: 2px;
  margin-bottom: 20px;
}

.application__panel--ttl {
  background-color: #f5f5f5;
  font-size: 13px;
  line-height: 18.5px;
  font-family: "Roboto", sans-serif;
  padding: 10px 15px;
  border-bottom: solid 1px #D2D2D2;
}

.application__panel--body {
  padding: 15px;
}

.application__panel--body label {
  margin-bottom: 5px;
}

.application__panel--body input {
  margin: 5px 0px;
}

.application__panel--body input::placeholder {
  font-family: "Roboto", sans-serif;
}

.application__panel--counter {
  color: #767676;
  margin: 20px 0px;
}

.application__panel--colors {
  display: flex;
  justify-content: center;
}

.application__panel--colors img {
  width: 93px;
  height: 93px;
  border: solid 1px #D2D2D2;
}

.application__panel--colors img:nth-child(even) {
  border-left: none;
  border-right: none;
}

.application__panel--fonts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 130px;
}

.application__panel--fonts {
  text-align: center;
  padding: 5px;
}

.application__panel--fonts--example {
  font-size: 24px;
  margin: 0;
  text-align: center;
  color: #767676;
  line-height: 1.8;
}

.application__panel--fonts--font-name {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.application__buy--number {
  width: 46px;
  padding: 5px;
  height: 40px;
  margin-right: 35px;
}

.application__buy--btn {
  background-color: #169688;
  color: #FFFFFF;
  border: none;
  padding: 10px 18px;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.application__share-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.application__share-icon {
  background-color: #000;
  color: #FFFFFF;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 16px !important;
  line-height: 28px !important;
  text-align: center;
  transition: color .25s ease;
}

.application__share-icon:hover {
  transition: color .25s ease;
  background-color: #169688;
}

.application__fonts {
  margin-top: 5%;
}

.application__fonts-label {
  margin-bottom: 5px;
}

.application__fonts-option {
  text-align: center;
  padding: 5px;
}

.application__fonts-select {
  margin: 5px 0px;
}

.application__text {
  margin-top: 5%;
}

.application__quantity {
  margin-top: 5%;
}

.application__ux-msg {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #2c2c2c;
}

.application__ux-msg.red {
  color: #e5534c;
}

.application__relateds {
  padding: 50px 0px;
}

.application__relateds-title {
  position: relative;
  font-size: 22px;
  margin: 0 0 15px;
  text-align: center;
  padding: 20px 0;
  font-family: 'Roboto';
  font-weight: 600;
  text-transform: uppercase;
}

.application__relateds-title::before {
  border-top: 1px solid #dddddd;
  right: 0px;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
}

@media only screen and (max-width: 768px) {
  .application__relateds-title::before {
    visibility: hidden;
  }
}

.application__relateds-title--text {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 20px;
}

.application__relateds-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.application__relateds-1-4 {
  width: calc((100% / 4) - 20px);
}

@media only screen and (max-width: 992px) {
  .application__relateds-1-4 {
    width: calc((100% / 2) - 20px);
  }
}

@media only screen and (max-width: 600px) {
  .application__relateds-1-4 {
    width: 100%;
  }
}

.search-empty__icon {
  width: 100%;
  height: 72px;
  margin: 5% auto;
  max-width: 73px;
}

@media only screen and (max-width: 480px) {
  .search-empty__icon {
    margin: 10% auto;
  }
}

.search-empty__text {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
  width: 100%;
  color: #333;
  line-height: 41px;
}

.search-empty__button {
  display: inline-block;
  transition: color 0s linear;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #AAA;
  padding: 9px 28px;
  line-height: 15px;
  margin: 20px auto 0px auto;
  position: relative;
  cursor: pointer;
}

.search-empty__button:before {
  content: "\f104";
  position: absolute;
  left: 15px;
  font-size: 14px;
  top: 50%;
  margin-top: -8px;
  color: #FFFFFF;
  font-family: FontAwesome;
}

.search-empty__button:hover {
  background-color: #e5534c;
}

.search__container {
  margin-bottom: 15px;
  height: auto;
  overflow: hidden;
}

.search__container--img {
  object-fit: cover;
  object-position: center;
  height: 208px;
}

.search__pipe {
  font-size: 16px;
  margin: 0 5px;
}

.search__pipe:last-of-type {
  display: none;
}

.search__sbttl {
  margin: 0 0 20px;
  text-transform: uppercase;
  text-align: center;
}

.search__text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.search__text .ttl-product {
  margin: 15px 0px;
  text-transform: uppercase;
  line-height: 20px;
}

.search__text .ttl-product:hover {
  color: #169688;
  text-decoration: underline;
}

.preview-modal__title {
  display: block;
  width: 100%;
  text-align: center;
  margin: 5% 0px;
}

.preview-modal__info-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 5%;
}

.preview-modal__close {
  position: absolute;
  right: 40px;
  font-size: 30px;
  top: 20px;
  cursor: pointer;
}

.thankyou-page__title {
  width: 100%;
  text-align: center;
  line-height: 39px;
}

@media only screen and (max-width: 480px) {
  .thankyou-page__title {
    margin: 35px 0px;
  }
}

.thankyou-page__payment-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 0px;
}

.thankyou-page__payment-items {
  font-size: 13px;
  color: #333;
  line-height: 24px;
}

.thankyou-page__payment-items--bold {
  font-weight: bold;
}

.thankyou-page__spei {
  color: #767676;
}

.thankyou-page__section {
  width: 100%;
  padding: 30px 0px;
}

.thankyou-page__section-title {
  font-weight: 400;
  line-height: 40px;
  border-bottom: 2px solid #000;
  margin-bottom: 10px;
}

.thankyou-page__section-label {
  color: #252525;
  line-height: 21px;
}

.thankyou-page__section-label--small {
  font-size: 13px;
}

.thankyou-page__section-label--bold {
  font-weight: bold;
}

.thankyou-page__section-label--lowercase {
  text-transform: lowercase;
}

@media only screen and (max-width: 480px) {
  .thankyou-page__section-label {
    font-size: 12px;
    letter-spacing: 0;
  }
}

.thankyou-page__section-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #D2D2D2;
}

.thankyou-page__section-row--wrap {
  flex-wrap: wrap;
}

.thankyou-page__address {
  padding-top: 30px;
  width: calc(50% - 10px);
}

.thankyou-page__address-title {
  font-size: 24px;
  color: #333 !important;
}

.thankyou-page__address-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .thankyou-page__address {
    width: 100%;
  }
}

.thankyou-page__invoice {
  padding: 30px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  flex-wrap: wrap;
}

.thankyou-page__invoice-button {
  background-color: #169688;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0px 28px;
  text-align: center;
  margin-top: 10px;
}

@media only screen and (max-width: 480px) {
  .thankyou-page__invoice {
    width: 100%;
  }
}

.profile__count {
  line-height: 19px;
  font-weight: lighter;
  color: #333;
}

.profile__count b {
  font-weight: bold;
}

.profile__count a {
  color: #000;
  transition: color .25s ease;
}

.profile__count a:hover {
  color: #169688;
}

.profile__menu-container {
  width: 100%;
}

.profile__menu-list {
  list-style: disc;
  padding-left: 13px;
  margin-bottom: 10px;
}

.profile__menu-items {
  line-height: 19px;
  text-transform: initial;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  transition: color .25s ease;
  color: #000;
}

.profile__menu-items:hover {
  color: #169688;
  text-decoration: underline;
}

.profile__account {
  width: 100%;
  margin-bottom: 20px;
}

.profile__account-title {
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  color: #333;
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  margin: 20px 0px;
  display: block;
}

.profile__account-label {
  color: #767676;
  font-weight: lighter;
}

.profile__account-input {
  color: #767676;
}

.profile__account-button {
  background-color: #169688;
  color: #FFFFFF;
  padding: 6px 18px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.profile__account-link {
  float: right;
  color: #767676;
  margin-top: 10px;
  transform: translateY(50%);
}

.profile__account-link:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #767676;
}

.profile__input-container {
  margin: 20px 0px;
}

.profile__button-container {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile__1-2 {
  width: calc(50% - 50px);
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .profile__1-2 {
    width: 100%;
  }
}

.profile__logout-form {
  display: inline-block;
}

.profile__logout-label {
  height: auto;
  padding: 0px;
}

.profile__logout-label:hover {
  cursor: pointer;
}

.address {
  width: 100%;
}

.address__title {
  color: #000;
  padding: 20px 0px;
}

.address__quote {
  color: #767676;
  font-weight: lighter;
  margin-bottom: 10px;
}

.address__subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 35px;
}

.address__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.address__col-1-3 {
  padding: 45px 0px 40px 0px;
  width: calc((100% / 3) - 30px);
  border-bottom: 1px solid #C1C1C1;
}

.address__col-1-3--no-padding {
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  .address__col-1-3 {
    width: 100%;
  }
}

.address__link {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  transition: color .25s ease;
}

.address__link:hover {
  color: #169688;
}

.address__empty {
  color: #333;
  font-weight: normal;
  margin-bottom: 10px;
}

.address__shipping {
  line-height: 19px;
  color: #333;
  font-weight: normal;
}

.address__input {
  margin: 10px 0px;
}

.address__input-container {
  margin-top: 10px;
  width: 100%;
}

.address__required {
  color: red;
  margin-left: 5px;
}

.address__form {
  width: 100% !important;
  align-items: flex-end !important;
}

.orders__table {
  width: 100%;
}

.orders__title {
  font-family: 'Raleway';
  font-size: 15px;
  text-transform: uppercase;
  color: #252525;
  vertical-align: middle;
  letter-spacing: 1px;
  padding: 22px 0 !important;
  line-height: 1.42857143;
  text-align: left;
}

.orders__content, .orders__empty {
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd;
  font-size: 15px;
  font-family: 'Raleway';
  vertical-align: middle;
  padding: 8px;
  text-align: left;
}

.orders__empty {
  text-align: center;
  font-weight: bold;
  border-top: none;
}

.orders__button {
  background-color: #169688;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 6px 18px;
}

.cards__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 50px;
}

.cards__form, .address__form {
  width: calc((100% / 3) - 20px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.cards--text-center {
  text-align: center;
}

.cards--text-right {
  text-align: right;
}

.cards--text-bold {
  font-weight: bold;
}

.cards__button {
  background-color: #169688;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  height: 40px;
  text-align: center;
  padding: 6px 12px;
  margin: 10px 0px;
}

.cards__title {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 35px;
}

.billings__title {
  color: #000;
  padding: 20px 0px;
}

.billings__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.billings__two-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .billings__two-inputs {
    flex-wrap: wrap;
  }
}

.billings__two-inputs--container {
  width: calc((100%/2) - 20px);
}

@media only screen and (max-width: 480px) {
  .billings__two-inputs--container {
    width: 100%;
  }
}

.billings__labels {
  font-family: 'Cabin';
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
  color: #767676;
}

@media only screen and (max-width: 480px) {
  .billings__labels {
    font-size: 13px;
  }
}

.billings__inputs {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: initial;
  padding: 10px;
  height: 39px;
  font-weight: lighter;
  font-size: 14px;
  margin: 5px 0px 15px 0px;
}

@media only screen and (max-width: 480px) {
  .billings__inputs {
    font-size: 13px;
  }
}

.billings__button {
  background-color: #169688;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  height: 40px;
  font-weight: normal;
  text-align: center;
  padding: 6px 40px;
  width: auto;
  margin-left: auto;
}

.billings__button-container {
  margin-top: 20px;
  width: 100%;
}

.billings__checkboxes--align {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: baseline;
  align-items: baseline;
  width: 100%;
}

.billings--width {
  width: 100%;
  margin: 5px 0px;
}

.billings__terms {
  color: #000;
}

.billings__terms:hover {
  text-decoration: underline;
}
