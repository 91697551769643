.alert {
	border:1px solid transparent;
	font-size: 13px;
	color: #767676;
	padding: 15px;
	width: 100%;
	position: relative;
	padding-left: 60px;
	line-height: 24px;
	margin-top: 20px !important;
	box-sizing: border-box;
	font-family: f(roboto);
	//display: none;
	&:before{
		content:"";
		position: absolute;
		top:50%;
		left:25px;
		margin-top: -11px;
		line-height: 1;
		font-size: 24px;
		font-family: 'Cabin';
	}
	&__success,
	&__danger {
		width: 100%;
		position: relative;
		display: none;
	}
	&__success {
		background-color: c(light-green);
	    border-color: c(dark-green);
	    &:before{
	        content:"\f00c";
	    }
	}
	&__danger {
		background-color: c(light-red);
	    border-color: c(dark-red);
    	&:before{
        	content:"\f00d";
    	}
	}
	&__container  {
		position: relative;
		background-color: c(white);
		width: 100%;
		@include ease-transition;
	}
	&__close {
		top: 12px;
		right: 12px;
		width: 20px;
		height: 20px;
		color: black;
		cursor: pointer;
		opacity: 0.4;
		position: absolute;
		font-size: 18px;
		font-weight: bold;
		line-height: 1;
		border-radius: 2px;
		background: rgba(0,0,0,0.1);
		&:hover {
			background: rgba(0,0,0,0.2);
		}
	}
	&__text {
		@extend %paragraph !optional;
		color: c(base);
		font-size: 14px;
		line-height: 21px;
		font-family: f(roboto);
	}
}
