.cards {
	&__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-top: 50px;
	}
	&__form {
		width: calc((100% / 3) - 20px);
	    display: flex;
	    flex-direction: column;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: center;
		margin: 0 auto;
	}
	&--text-center {
		text-align: center;
	}
	&--text-right {
		text-align: right;
	}
	&--text-bold {
		font-weight: bold;
	}
	&__numbers {
		@extend %footer-price;
	}
	&__button {
		@extend %btn-add-car;
		background-color: c(blue-green);
		color: c(white);
		border: none;
		cursor: pointer;
		height: 40px;
		text-align: center;
		padding: 6px 12px;
		margin: 10px 0px;
	}
	&__title {
		font-family: f(roboto);
		font-size: 22px;
		color: c(black);
		text-transform: uppercase;
		padding-bottom: 35px;
	}
}
