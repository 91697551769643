.application {
	&__container {
		margin: 0 auto;
	    position: relative;
	    overflow: hidden;
	    list-style: none;
	    padding: 0;
	    z-index: 1;
		width: 100%;
    	height: auto;
	}

	&__col-1-2--sticker {
		@include binary-column-maker($gutter: 20px);
	}

	&__main-img {
		margin: 0 auto;
	    position: relative;
	    overflow: hidden;
	    list-style: none;
	    padding: 0;
	    z-index: 1;
		width: 100%;
    	height: auto;
		@include under(md) {
			max-width: 450px;
		}
	}
	&__slide {
		text-align: center;
	    font-size: 18px;
	    background: c(white);
	    height: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
		padding: 5px;
		&.selected {border: solid 1px c() !important;}

		&.swiper-slide { // para las slides de los selects
			&.swiper-slide-active {border:none}
		}
	}
	&__gallery {
		padding: 15px 35px;
		position:relative;
		&-button {
		width: 31px;
		position: absolute;
		height: 31px;
		border: 1px solid #eee;
		border-radius: 100%;
		transition: all 0.2s ease-in-out;
		top: 40%;
		bottom: 40%;
		z-index: 9;
		text-align: center;
		line-height: 26px;
			&:before{
				position: relative;
				font-size: 20px;
				font-family: FontAwesome;
				color: c(black-dark);
				top: 0px;
				transition: all 0.2s ease-in-out;
			}
			&:hover {
				background-color: c(black-dark);
				border-color: c(black-dark);
				cursor: pointer;
				&:before{
					color: c(white);
				}
			}
			&--next {
				right: 0px;
				&:before {
					content: "\f105";
				}
			}
			&--prev {
				left: 0px;
				&:before {
					content: "\f104";
				}
			}
		}
	}
	&--background-color {
		background-color: c(background-grey);
		padding-bottom: 30px;
		padding-top: 30px;
	}
	&__ttl {
		@extend %ttl-show-product;
		padding-bottom: 10px;
		width: 100%;
	}
	&__description {
		@extend %label-menu;
		line-height: 15.6px;
		&--txt {
			padding-bottom: 10px;
			&:first-of-type {
				font-weight: 700;
			}
		}
		&--quantity {
			font-weight: 700;
		}
	}
	&__important {
		@extend %label-menu;
		color: c(grey);
		line-height: 20px;
		&--ttl {
 			font-weight: 700;
		}
		&--link {
			color: c(black);
			&:hover {
				color: c(blue-green);
			}
		}
	}
	&__prices {
		display: flex;
		padding: 32px 0px 43px 0px;
		font: {
			weight: 600;
			family: f(roboto);
		}
		&--real {
			font-size: 22px;
			text-decoration:line-through;
			padding: 0px 5px;
		}
		&--final {
			font-size: 30px;
			color: c(blue-green);
			padding: 0px 5px;
		}
	}
	&__panel {
		width: 100%;
		height: auto;
		min-height: 170px;
		border: solid 1px c(lighten-grey);
		border-radius: 2px;
		margin-bottom: 20px;
		&--ttl {
			background-color: c(background-grey);
			font-size: 13px;
			line-height: 18.5px;
			font-family: f(roboto);
			padding: 10px 15px;
			border-bottom:  solid 1px c(lighten-grey);
		}
		&--body {
			padding: 15px;
			label {
				@extend %sbttl-products-related;
				margin-bottom: 5px;
			}
			input {
				margin: 5px 0px;
				&::placeholder {
					font-family: f(roboto);
				}
			}
		}
		&--counter {
			@extend %sbttl-show-product;
		 	color: c(grey);
			margin: 20px 0px;
		}
		&--colors {
			display: flex;
			justify-content: center;
			img {
				width: 93px;
				height: 93px;
				border: solid 1px c(lighten-grey);
				&:nth-child(even) {
					border-left: none;
					border-right: none;
				}
			}
		}
		&--fonts-container {
      		display: flex;
      		justify-content: center;
      		align-items: center;
      		min-height: 130px;
    	}
	    &--fonts {
	      text-align: center;
	      padding: 5px;
	      &--example {
	        font-size: 24px;
			margin: 0;
			text-align: center;
			color: c(grey);
			line-height: 1.8;
	      }
	      &--font-name{
	        font-family: f(roboto);
	        font-size: 13px;
			display: block;
			margin: 0 auto;
			text-align: center;
	      }
	    }
	}
	&__buy {
		&--number {
			width: 46px;
			padding: 5px;
			height: 40px;
			margin-right: 35px;
		}
		&--btn {
			@extend %btn-add-car;
			background-color: c(blue-green);
			color: c(white);
			border: none;
			padding: 10px 18px;
			width: 100%;
			height: 40px;
			text-transform: uppercase;
			cursor: pointer;
			max-width: 315px;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
	&__share{
		&-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			width: 100%;
			max-width: 100px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
		}
		&-icon {
			background-color: c(black);
			color: c(white);
			width: 28px;
			height: 28px;
			border-radius: 100%;
			font-size: 16px !important;
			line-height: 28px !important;
			text-align: center;
			transition: color .25s ease;
			&:hover {
				transition: color .25s ease;
				background-color: c(blue-green);
			}
		}
	}
	&__fonts {
		margin-top: 5%;
		&-label {
			@extend %sbttl-products-related;
			margin-bottom: 5px;
		}
		&-option {
			text-align: center;
			padding: 5px;
		}
		&-select {
			margin: 5px 0px;
		}
	}
	&__text {
		margin-top: 5%;
	}
	&__quantity {
		margin-top: 5%;
	}

	&__ux-msg {
		font-size: 16px;
		margin-bottom: 20px;
		font-family: f(BenchNine);
		font-weight: bold;
		color: c(black-grey);

		&.red {
			color: c(red);
		}
	}
	&__relateds {
		padding: 50px 0px;
		&-title{
			position: relative;
			font-size: 22px;
		    margin: 0 0 15px;
		    text-align: center;
		    padding: 20px 0;
			font-family: 'Roboto';
		    font-weight: 600;
		    text-transform: uppercase;
			&::before {
				border-top: 1px solid #dddddd;
				right: 0px;
				width: 100%;
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				@include under(sm) {
					visibility: hidden;
				}
			}
			&--text {
				background-color: #fff;
				position: relative;
				z-index: 1;
				padding: 0 20px;
			}
		}
		&-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			align-items: center;
			width: 100%;
		}
		&-1-4 {
			width: calc((100% / 4) - 20px);
			@include under(md) {
				width: calc((100% / 2) - 20px);
			}
			@include under(sm-smaller) {
				width: 100%;
			}
		}
	}
}
