.header {
    /* primera parte de menú */
	&__navbar {
		@include under(md) {
			display: none;
		}
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
		&--elements {
			width: calc(100%/2);
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
	.elements {
		position: relative;
		@include under(md) {
			top: 5px;
    		right: 15px;
		}

		&__login {
			text-transform: uppercase;
			color: c(black);
			letter-spacing: 1px;
			font-size: 12px;
			font-family: f(roboto);
			padding: 0px 40px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
			position: relative;
			& > a {
				&:hover {
					color: c(blue-green);
					text-decoration: underline;
					cursor: pointer;
				}
			}
			& > form input {
				text-transform: uppercase;
				color: c(black);
				letter-spacing: 1px;
				font-size: 12px;
				font-family: f(roboto);
				font-weight: normal;
				padding: 0px;
				height: auto;
				&:hover {
					color: c(blue-green);
					text-decoration: underline;
					cursor: pointer;
				}
			}
			&-anchor {
				&:hover {
					color: c(blue-green);
					text-decoration: underline;
				}
			}

			&:hover .elements__login--tooltip {
				visibility: visible;
			}
			&--tooltip {
				width: 300px;
				background-color: c(white);
				border: solid 3px c(lighter-grey);
				visibility: hidden;
				position: absolute;
    			z-index: 999999;
				padding: 20px;
				top: 30px;
				@include under(md) { top: 16px; }
				right: 0px;
				transition-duration: 1s;
				&::after{
					content: "";
					position: absolute;
					border-width: 7px;
					border-style: solid;
					border-color: lightgray transparent transparent transparent;
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
					top: -15px;
					left: 150px;
				}
          	}
			&--ttl {
				display: flex;
				text-transform: uppercase;
				font-size: 15px;
				font-weight: 500;
				letter-spacing: 1px;
				color: c(black);
				padding-bottom: 10px;
				p {
					width: 130px;
				}
				&--mb {
					margin-bottom: 10px;
				}
			}
			&--form {
				label {
					font-size: 14px;
					color: c(grey);
					text-transform: lowercase;
					line-height: 22px;
				}
				input {
					margin: 10px 0px;
				}
			}
			&--line {
				width: 50%;
				border-bottom: solid 1px c(black);
				align-self: center;
			}
			&--btn-acceder {
				font-size: 13px;
				color: c(white);
				letter-spacing: 1px;
				margin: 10px 0px;
				padding: 10px 22px;
				background-color: c(blue-green);
				border: transparent;
				width: 100%;
				&:hover {
					cursor: pointer;
				}
			}
			&--btn-registrar {
				background-color: c(white);
				border: solid 2px c(black);
				color: c(black);
				text-transform: uppercase;
				padding: 10px 22px;
				&:hover {
					background-color: c(black);
					color: c(white);
					text-decoration: underline;
				}
			}
		}
		&__search {
			width: 33px;
			height: 33px;
			font-size: 12px;
			background-color:c(lighter-grey);
			display: flex;
			color: c(white);
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			line-height: 10px;
			cursor: pointer;
			margin-right: 8px;
			position: relative;
			&:hover .elements__search--tooltip {
				visibility: visible;
			}
			&--tooltip {
				display: flex;
				max-width: 350px;
				background-color: c(white);
				border: solid 3px c(lighter-grey);
				visibility: hidden;
				position: absolute;
				z-index: 999999;
				padding: 20px;
				top: 30px;
				right: 0px;
				 transition-duration: 1s;
				&::after{
					content: "";
					position: absolute;
					border-width: 7px;
					border-style: solid;
					border-color: lightgray transparent transparent transparent;
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
					top: -15px;
					right: 10px;
				}
				input {
					width: 150px;
				}
				button {
					background-color: c(lighten-grey);
					border: none;
					font-size: 13px;
					text-transform: uppercase;
					color: c(white);
					line-height: 15px;
					margin-left: 30px;
					padding: 12px 40px;
					width: 132px;

				}
			}
		}

		&__buy {
			padding: 10px 15px;
			background-color: transparent;
			font-family: f(roboto);
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			position: relative;
			@include over(md){
				border: 1px dashed c(lighten-grey);
			};
			@include under(md){
				padding: 0;
			}
			i {
				position: absolute;
				top: -18px;
				right: 15px;
				font-size: 22px;
				cursor: pointer;
				color: c(blue-green);
			}
			& .total-price {
				@include under(md) {
					display: none;
				}
			}

			&--circle {
				width: 20px;
				height: 20px;
				background-color:c(lighten-grey);
				font-size: 13px;
				padding: 11px;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: normal;
				position: absolute;
				top: -35px;
				right: 0px;
				@include under(md) {
					top: -25px;
					right: -3px;
				}

			}
			&:hover .elements__buy--tooltip {
				visibility: visible;
			}
			&--tooltip {
				width: 300px;
				background-color: c(white);
				border: solid 3px c(lighter-grey);
				position: absolute;
				z-index: 999999;
				padding: 20px;
				top: 50px;
				@include under(md) {
					top: 17px;
				}
				right: 0px;
				transition-duration: 1s;
				font-family: f(roboto);

				&::after{
					content: "";
					position: absolute;
					border-width: 7px;
					border-style: solid;
					border-color: lightgray transparent transparent transparent;
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
					top: -17px;
					right: 0px;
				}
			}
			&--ttl {
				color: c(grey);
				font-size: 14px;
				line-height: 25.2px;
				text-align: left;
			    font-weight: 400;
			    padding: 8px;
				border-bottom: solid 1px c(lighten-grey);
			}
			&--description {
				display: flex;
				width: 100%;
				padding: 8px;
				flex-wrap: nowrap;
				border-bottom: solid 1px c(lighten-grey);

			}
			&--txt {
				font-family: f(roboto);
				font-size: 14px !important;
				font-weight: 400 !important;
				text-transform: uppercase;
				padding: 5px;
				text-align: left;
				p:last-of-type {
					font-weight: 600;
				}
			}
			&--icon {
				i {
					position:absolute;
					top: 98px;
					color: c(lighten-grey);
				}
			}
			&--subtotal {
				display: flex;
				width: 100%;
				justify-content: space-between;
				text-transform: uppercase;
				font-weight: 400;
				padding: 15px 8px;
				font-size: 14px;
				p:last-of-type {
					font-weight: 600;
				}
			}
			&--botones {
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 13px;
    			text-align: center;
				a:first-child {
					color: c(white);
					background-color: c(lighten-grey);
					padding: 13px 6px;
					margin-bottom: 10px;
					width: 100%;
					font-weight: 400;
					&:hover {
						background-color: c(grey);
					}
				}
				a:last-child {
					color: c(white);
					background-color: c(black);
					padding: 12px 6px;
					margin-bottom: 10px;
					width: 100%;
					font-weight: 400;
					&:hover {
						background-color: c(blue-green);
					}
				}
			}

			&--img {
				max-width: 80px;
				width: 100%;
				margin-right: 10px;
				height: auto;
				&-size {
					object-fit: contain;
				    width: 100%;
				    height: 100%;
				}
			}
		}
	}
    /* seguda parte de menú */
    &__navbarList {
		@media screen and (max-width:1160px){
          display: block;
       }
    	@include under(md) {
         	display: none;
        }
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-top: solid 1px lightgrey;
        border-bottom: solid 1px lightgrey;
		&--fixed {
			border: none !important;
		}
		&--active {
			color: c(blue-green) !important;
		}
		&--arrow {
			color: #b9b9b9;
			font-size: 9px !important;
			font-weight: 100 !important;
			margin-bottom: 0px;
		}
		&--container {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: left;
			align-items: center;
		}
        &--menuOption {
			@media screen and (max-width:1160px){
			  padding: 22px 10px;
		   }

            padding:22px;
            border-left: solid 1px lightgrey;
            // height: 59px;
            text-align: center;
			text-transform: uppercase;
			display: flex;
			position: relative;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			&:last-of-type {
				border-right: solid 1px lightgrey;
			}

            &text{
                color: black;
                text-decoration: none;
                font-weight: 600;
                font-family:f(roboto);
                font-size: 12px;
                text-align: center;


                &:hover{
                    color:#169688;
                }
            }
			&:hover {
				.header__navbarList--dropdown--dropdownContainer {
					display: block;
				}
			}

         }

        &--dropdown {
            position: relative;
            display: inline-block;
            width: 250px;

            &--dropdownContainer{
                display:none;
                position: absolute;
                background-color: c(white);
                overflow: auto;
                z-index: 9;
                border: solid 1px c(lighten-grey);
				width: 100%;
                padding: 15px 0px;
                top: 56px;
				left: 0;
                &Link{
                    color: c(grey);
                    text-decoration: none;
                    display: block;
                    font-size: 12px;
                    font-family: "RalewayRegular", sans-serif;
                    padding: 8px 20px;
					word-break: break-word;
					text-align: left;
                    &:hover{
                    	color:c(blue-green);

                    }
                    }
                }

            }

        &--menuOptionLast{
            padding:22px;
            border-left: solid 1px lightgrey;
            text-align: center;
            font-family: f(roboto);
			text-transform: uppercase;
        }
                .show{
                display:block;
                }

   }
   &__scroll {
	   &--hide {

	   }
   }
   &__fixed {
		background-color: c(white);
		//transition: 0.4s all ease-in-out;
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 888888;
		border-bottom: 1px solid c(lighten-grey);
		box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
		overflow: visible !important;
		@include under(md) {
			display: none;
		}
	   	&-logo {
			width: 164px;
		}
		&-cart {
			width: 150px;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: space-evenly;
			align-items: flex-end;
		}
		&-items {
			width: calc(100% - 165px - 150px)
		}
		&-container {
			flex-direction: row;
		    flex-wrap: nowrap;
		    justify-content: space-between;
		    align-items: center;
			margin: 15px auto;
			background-color: c(white);
		}
   }
}
.element {
	&__buy {
		&--img {
			max-width: 80px;
			width: 100%;
			margin-right: 10px;
			height: auto;
			&-size {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}
	}
}
  /*termina header y aparte sidenav*/
