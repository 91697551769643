// navside
.navside {
	width: 250px;
	background-color: #1a1a1a;
	height: 100%;
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 100%;
	overflow-x: hidden;
	//transition: all 1s ease-in-out;
	&__container {
		width: 100%;
		font-family: f(roboto);
		color: c(white);
		text-transform: uppercase;
		font-size: 13px;
		display: flex;
		background-color: c(black-light);
		flex-direction: column;
		align-items: center;
	}
	&__title{
		width: 100%;
	    padding-left: 17px;
	    font-size: 18px;
	    font-weight: 700;

		&-bars{
			font-size: 16px;
		}
		&-container {
			background-color: #1e1e1e;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 20px 15px 20px 30px;
			&:hover{
				cursor: pointer;
				.navside__close {
					background-position: bottom center;
				}
			}
		}
	}
	&__close {
		color: c(lighter-grey);
		background-image: url(/images/small-close.png);
		background-repeat: no-repeat;
		background-position: top center;
		overflow: hidden;
		width: 16px;
		height: 14px;
	}
	&__options {
		flex-direction: column;
	    position: absolute;
	    top: 75px;
	    width: 100%;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;

	}

	&__menu {
		&--item {
			font-size:14px;
			font-style:normal;
			font-weight:700;
			position: relative;

			&-head{
				padding: 15px 20px 15px 25px;
				width: 100%;
				display: block;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			&-link, &-arrow {

				&:hover {
					color: c(blue-green);
					cursor: pointer;
				}

			}

			&-arrow {
				font-size: 12px !important;
				font-weight: lighter !important;
				// &.active{
				// 	display:inline-block !important;
				// }
			}

			&-active {
				color: c(blue-green);
			}

			&-list{
			    background-color: #2c2c2c;
		        padding: 15px 5px 15px 35px;
				margin: 15px 0px;
				display: none;
				// &.active {
				// 	display: block;
				// }
				&::before{
					content: '';
					display: block;
					left: 30px;
					top: 40px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(136, 183, 213, 0);
					border-bottom-color: #2c2c2c;
					border-width: 10px 10px;
				}
			}

			&-subitem {
				color: #767676;
				font-size: 12px;
				padding: 13px 0;

				&:hover,&-active {
					color: c(blue-green);
					cursor: pointer;
				}

			}
		}
	}

	&__user {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 15px 30px 0px 30px;
		&--account {
		    border-bottom: 1px solid #262626;
			font-size: 12px;
		    font-weight: 700;
		    width: 100%;
		    padding: 15px 30px 15px 0px;
		    letter-spacing: 1px;
		    margin-bottom: 10px;
		    margin-top: 20px;
			&-icon {
				font-size: 16px !important;
				margin-right: 10px;
			}
		}
		&--login {
			text-align: left;
			font-size: 12px;
			font-weight: 700;
			line-height: 20px;
    	    padding: 15px 0 15px 0px;
			&:hover {
				color: c(blue-green);
				cursor: pointer;
			}
		}

		&--logout{
			text-transform: uppercase;
			color: c(white);
			font-size: 12px;
			font-family: f(roboto);
			font-weight: 700;
			padding: 0px;
			height: auto;
			background-color: transparent;
			border: none;
			width: auto;
			&:hover {
				color: c(blue-green);
				cursor: pointer;
			}
		}
	}
}
